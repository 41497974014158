<template>
    <div v-if="type == 'row'" class="step">
        <ul class="step1">
            <template v-for="(item1, firstIndex) in cateData.data" :key="firstIndex">
                <li v-if="firstIndex < 11" class="step1_li" @mouseover="selectTab(firstIndex, item1.categoryId)">
                    <a class="step1_a" @click.stop="toGoodsList(item1)"
                        :style="(2 < firstIndex && firstIndex < cateData.data.length - 3) ? 'position:relative' : ''"
                        :class="{ active: item1.categoryId == categoryId }">
                        {{ item1.categoryName }}
                        <div class="step2"
                            :style="[firstIndex < 3 ? 'left:0;transform:none;' : '', firstIndex > cateData.data.length - 4 ? 'left:unset;right:0;transform:none;' : '']"
                            @click.stop="handEvent">
                            <el-scrollbar class="scrollbar">
                                <ul class="step2_ul">
                                    <li class="step2_li flex_column_center_center"
                                        v-for="(item2, index) in cateDataFirst.data" :key="index">
                                        <a class="step2_a" @click.stop="toGoodsList(item2)">
                                            {{ item2.categoryName }}
                                        </a>
                                        <a class="step3" v-for="(item3, thirdIndex) in item2.children" :key="thirdIndex"
                                            @click.stop="toGoodsList(item3)">
                                            <div :style="{ backgroundImage: 'url(' + item3.categoryImage + ')' }"></div>
                                            <span>{{ item3.categoryName }}</span>
                                        </a>
                                    </li>
                                </ul>
                            </el-scrollbar>
                        </div>
                    </a>
                </li>
            </template>
        </ul>
    </div>
    <div v-else-if="type == 'list'" id="category_sort">
        <ul class="menu_wrap">
            <!-- 一级分类 start-->
            <li class="menu_item" v-for="(item1, firstIndex) in cateData.data" v-bind:key="firstIndex"
                style="position:static;" @mouseover="selectTab(firstIndex, item1.categoryId)">
                <a class="first_cat" @click.stop="toGoodsList(item1)">
                    {{ item1.categoryName }}</a>
                <div class="children" @click.stop="void (0)">
                    <!-- 二级分类 start-->
                    <div v-if="cateDataFirst.data.length > 0">
                        <dl class="fore_dl" v-for="(item2, index) in cateDataFirst.data" v-bind:key="index">
                            <dt>
                                <a class="second" @click.stop="toGoodsList(item2)">
                                    {{ item2.categoryName }}</a>
                                <i>&gt;</i>
                            </dt>
                            <dd>
                                <!-- 三级分类 start-->
                                <a class="third" @click.stop="toGoodsList(item3)"
                                    v-for="(item3, thirdIndex) in item2.children" v-bind:key="thirdIndex">{{
                                        item3.categoryName
                                    }}</a>
                            </dd>
                        </dl>
                    </div>
                    <SldCommonEmpty v-else totalWidth="900" totalHeight="300" style="color:#666" />
                    <!-- 二级分类 end-->
                </div>
            </li>
            <!-- 一级分类 end-->
        </ul>
    </div>
</template>
<script>
import { getCurrentInstance, reactive, onMounted, ref, watchEffect } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import SldCommonEmpty from './SldCommonEmpty'
export default {
    name: 'CategorySort',
    components: {
        SldCommonEmpty
    },
    props: {
        type: {
            type: String,
            default: 'list'
        }
    },
    setup() {
        const router = useRouter()
        const { proxy } = getCurrentInstance()
        const L = proxy.$getCurLanguage()
        const route = useRoute()
        const cateData = reactive({ data: [] })
        const cateShow = ref(false)
        const cateDataFirst = reactive({ data: [] })
        const cateCache = reactive({ data: [] })
        const categoryId = ref(route.query.categoryId);

        watchEffect(() => {
            categoryId.value = route.query.categoryId;
        })

        const getInitData = () => {
            proxy.$get('v3/goods/front/goods/category/topCategory').then(res => {
                if (res.state == 200) {
                    cateData.data = res.data.slice(0, 12)
                }
            })
        };

        const selectTab = (index, categoryId) => {
            // cateShow.value = true
            cateDataFirst.data = []
            let params = {
                categoryId1: categoryId
            }
            let index1 = cateCache.data.findIndex(item => item.categoryId == categoryId)
            if (index1 > -1) {
                cateDataFirst.data = cateCache.data[index1].list
            } else {
                getSortChild(index, params)
            }
        };

        const getSortChild = (index, params) => {
            proxy.$get('v3/goods/front/goods/category/bottomCategory', params).then(res => {
                if (res.state == 200) {
                    let index1 = cateCache.data.findIndex(item => item.categoryId == params.categoryId1)
                    if (index1 > -1) {
                        cateDataFirst.data = cateCache.data[index1].list
                    } else {
                        cateDataFirst.data = res.data
                        cateCache.data.push({ categoryId: params.categoryId1, list: res.data })
                    }
                }
            })
        };

        const toGoodsList = (item) => {
            let newWin = router.resolve({
                path: '/goods/list',
                query: {
                    categoryId: item.categoryId,
                    g: item.grade,
                    pid: item.pid
                }
            })
            window.open(newWin.href, '_blank');
        };

        const handEvent = () => { };

        onMounted(() => {
            getInitData(0, {})
        })
        return { L, cateData, cateDataFirst, selectTab, cateShow, toGoodsList, categoryId, handEvent }
    }
}
</script>
<style lang="scss" scoped>
.index {
    background: #f8f8f8;
    padding-bottom: 10px;
}

ul,
ol,
li {
    list-style: none;
}

a,
a:visited,
a:link {
    text-decoration: none;
    color: #666;
}

#category_sort {
    font-size: 12px;
    display: none;
    position: absolute;
    left: 0px;
    top: 43px;
    width: 187px;
    height: 459PX;
    z-index: 9;
    background-color: rgba(0, 0, 0, 0.6);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    .menu_wrap {
        .menu_item {
            height: 38px;
            line-height: 38px;
            position: relative;
            border-left: 3px solid transparent;

            .first_cat {
                width: 125px;
                display: block;
                font-size: 14px;
                font-family: 'microsoft yahei';
                color: #ffffff;
                padding: 0 10px 0 15px;
                text-overflow: ellipsis;
                overflow: hidden;
                position: relative;
                letter-spacing: normal;

                &:hover {
                    color: var(--color_vice);
                }
            }

            &:hover {
                background: #393939;
                border-left-color: #F69913;

                .children {
                    display: inline-block;
                    -webkit-animation: showMore .2s ease-in-out;
                    animation: showMore .2s ease-in-out;

                    @-webkit-keyframes showMore {
                        0% {
                            opacity: 0;
                        }

                        to {
                            opacity: 1;
                        }
                    }

                    @keyframes showMore {
                        0% {
                            opacity: 0;
                        }

                        to {
                            opacity: 1;
                        }
                    }
                }
            }

            .children {
                display: none;
                width: 1013px;
                height: 458px;
                background-color: #fff;
                position: absolute;
                top: 0;
                left: 187px;
                padding: 10px 0;
                overflow: hidden;
                box-shadow: 0px 5px 10px 0px rgba(153, 153, 153, 0.15);

                .fore_dl {
                    width: 100%;
                    clear: both;
                    overflow: hidden;
                }

                dt {
                    position: relative;
                    float: left;
                    width: 84px;
                    padding: 5px 30px 0 0;
                    font-weight: 700;
                    line-height: 2em;
                    overflow: hidden;
                    white-space: nowrap;
                    margin-left: 30px;

                    i {
                        position: absolute;
                        top: 7px;
                        right: 8px;
                        width: 14px;
                        height: 14px;
                        font: 400 9px/14px consolas;
                        color: #333;
                    }

                    .second {
                        color: #666;
                        font: 700 12px/40px "microsoft yahei";
                        width: 54px;
                        overflow: hidden;
                        display: inline-block;
                        text-align: right;
                        line-height: 18px;
                        text-overflow: ellipsis;
                        letter-spacing: normal;

                        &:hover {
                            color: var(--color_vice);
                        }
                    }
                }

                dd {
                    border-top: none;
                    border-bottom: 1px dashed #dddddd;
                    width: 790px;
                    padding: 4px 0;
                    float: left;
                    line-height: 16px;
                    margin-left: 15px;
                    min-height: 32px;
                }

                .third {
                    float: left;
                    padding: 0 8px;
                    margin: 2px 0;
                    line-height: 16px;
                    height: 16px;
                    border-right: 1px solid #e0e0e0;
                    white-space: nowrap;
                    font-size: 12px;
                    letter-spacing: normal;

                    &:hover {
                        color: var(--color_vice);
                    }

                    &:last-child {
                        border-right: none;
                    }
                }


            }
        }
    }
}

.step {
    display: inline-block;
    height: 45px;
    line-height: 45px;
    color: #333333;
    font-size: 16px;
    box-sizing: border-box;
    flex-shrink: 0;

    .step1 {
        position: relative;
        display: flex;
        white-space: nowrap;

        .step1_li {
            .step1_a {
                // position: relative;
                display: inline-block;
                width: -webkit-max-content;
                width: -moz-max-content;
                width: max-content;
                height: 44px;
                line-height: 44px;
                color: #333333;
                font-size: 14px;
                font-weight: 700;
                box-sizing: border-box;
                margin-left: 10px;
                margin-right: 10px;
                padding-left: 3px;
                padding-right: 3px;
                cursor: pointer;

                &.active,
                &:hover {
                    color: var(--color_main);
                    border-bottom: 3px solid var(--color_main);
                }

                &:hover {

                    .step2 {
                        display: block;
                    }
                }

                .step2 {
                    display: none;
                    position: absolute;
                    top: 45px;
                    left: 50%;
                    transform: translateX(-50%);
                    z-index: 999;
                    overflow: hidden;
                    max-height: 430px;
                    background: #ffffff;
                    border: 1px solid rgba(0, 0, 0, .1);
                    border-top: none;
                    padding: 14px 18px;

                    &:hover {
                        display: block;
                    }

                    .step2_ul {
                        display: flex;
                        align-items: flex-start;
                        white-space: nowrap;

                        a {
                            flex-shrink: 0;
                            position: relative;
                            height: 44px;
                            display: inline-block;
                            box-sizing: border-box;
                            color: #333333;
                            margin-left: 10px;
                            margin-right: 10px;
                            line-height: 44px;
                            padding-left: 5px;
                            padding-right: 5px;
                            width: -webkit-max-content;
                            width: -moz-max-content;
                            width: max-content;
                        }

                        .step2_a {
                            min-width: 110px;
                            margin: 0;
                            font-size: 15px;
                            font-weight: 700;
                            border-bottom: 1px solid rgb(221, 221, 221);
                        }

                        .step3 {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            min-width: 70px;
                            margin: 15px 10px;
                            max-height: 48px;
                            font-size: 14px;
                            overflow-wrap: break-word;
                            overflow: hidden;

                            div {
                                width: 50px;
                                height: 50px;
                                background-repeat: no-repeat;
                                background-position: center;
                                background-size: contain;
                            }

                            span {
                                display: inline-block;
                                width: 74px;
                                line-height: 18px;
                                font-weight: 500;
                                white-space: normal;
                                word-break: break-all;
                                padding-left: 6px;
                            }

                            &:hover {
                                color: var(--color_main);
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
<style lang="scss">
.nav_cat {
    .step {
        .step2 {
            .el-scrollbar__view {
                max-height: 356px;
            }
        }
    }
}
</style>