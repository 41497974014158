<!--
 * @Author: your name
 * @Date: 2020-12-29 16:05:36
 * @LastEditTime: 2021-01-09 10:34:04
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /java-pc/src/views/home/Home.vue
-->
<template>
  <div class="home_adv_con" v-show="advTop.show_switch && route.fullPath == '/index'">
    <div class="home_adv" :style="{ backgroundImage: 'url(' + advTop.imgUrl + ')' }" @click="navTo">
      <div class="adv1200">
        <div class="close1" @click.stop="advTop.show_switch = false">×</div>
      </div>
    </div>
  </div>

  <NavTopBar />
  <SldHomeTopSearch />
  <!--<NavCatHeader />-->
  <div class="home_bottom_line"></div>
  <router-view v-if="isRouterAlive"></router-view>
  <FooterService />
  <FooterLink />
</template>

<script>
import { useRoute } from 'vue-router'
import NavTopBar from "../../components/NavTopBar";
import SldHomeTopSearch from "../../components/SldHomeTopSearch";
import NavCatHeader from "../../components/NavCatHeader";
import FooterService from "../../components/FooterService";
import FooterLink from "../../components/FooterLink";
import { ref, getCurrentInstance, onMounted } from 'vue'
export default {
  name: 'Home',
  components: {
    NavTopBar,
    SldHomeTopSearch,
    NavCatHeader,
    FooterService,
    FooterLink,
  },
  setup() {
    const close_layer_icon = require('@/assets/coupon/close_layer_icon.png')
    const route = useRoute()
    const isRouterAlive = true
    const { proxy } = getCurrentInstance()
    const advTop = ref({})
    const getAdv = () => {
      proxy.$get('v3/system/front/pcDeco/firstAdv', {
        type: 2
      }).then(res => {
        if (res.state == 200) {
          advTop.value = JSON.parse(res.data.data.replace(/&quot;/g, "\""))
        }
      })
    }

    const navTo = () => {
      proxy.$diyNavTo(advTop.value)
    }

    onMounted(() => {
      getAdv()
    })

    return { isRouterAlive, advTop, close_layer_icon, navTo, route }

  }
}
</script>
<style>
.home_bottom_line {
  width: 100%;
  height: 1px;
  background-color: rgb(232, 232, 232);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 3px 0px;
}

.home_adv_con {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 2999;
  width: 100%;
  height: 120px;
  background: rgba(0, 0, 0, .7);
  overflow: hidden;
  margin: 0 auto;
}

.home_adv {
  width: 1210px;
  height: 90px;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -45px;
  margin-left: -605px;
  cursor: pointer;
}

.adv1200 {
  width: 1200px;
  height: 90px;
  margin: 0 auto;
  position: relative;
}

.close1 {
  position: absolute;
  top: -11px;
  right: -15px;
  z-index: 2;
  width: 22px;
  height: 22px;
  line-height: 22px;
  color: rgba(0, 0, 0, .5);
  font-size: 17px;
  font-family: cursive;
  font-weight: 700;
  text-align: center;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
}
</style>