<!--
 * @Author: your name
 * @Date: 2021-01-09 20:19:11
 * @LastEditTime: 2021-01-13 17:21:02
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /java-pc/src/components/FooterBottom.vue
-->
<template>
  <div class="footer_bottom">
    <ul class="footer_list">
      <li v-for="({ linkName, linkUrl }, index) in navlist.data" :key="index">
        <a :href="linkUrl" target="_blank">
          {{ linkName }}
        </a>
      </li>
    </ul>
    <p class="copy_right">{{ configInfo.basic_site_copyright }} {{ L['版权所有'] }} <a href="https://beian.miit.gov.cn/"
        target="_blank">{{ configInfo.basic_site_icp }}</a></p>
    <p class="author">{{ configInfo.basic_site_technical_support }} {{ L['提供技术支持'] }}</p>
  </div>
</template>

<script>
import { reactive, getCurrentInstance, ref } from 'vue'
import { useStore } from 'vuex';
export default {
  name: "FooterBottom",
  setup() {
    const navlist = reactive({ data: [] });
    const store = useStore();
    const configInfo = ref(store.state.configInfo)
    const { proxy } = getCurrentInstance();
    const L = proxy.$getCurLanguage();
    const getInitData = () => {
      const { proxy } = getCurrentInstance();
      proxy.$get('v3/cms/front/friendLink/list').then(res => {
        if (res.state == 200) {
          navlist.data = res.data;
        }
      })
    }
    getInitData();
    return { navlist, configInfo, L };
  },
};
</script>

<style lang="scss" scoped>
.footer_bottom {
  position: absolute;
  left: 50%;
  margin-left: -952px;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 1900px;
  color: #888;
  font-size: 12px;
  background-color: #f0f0f0;
  padding-bottom: 46px;
  overflow: hidden;

  .footer_list {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    li {
      position: relative;
      box-sizing: border-box;
      padding: 0 17px;
      // border-right: 1px solid rgba(255, 255, 255, 0.4);
      color: #888;
      line-height: 19px;
      margin-bottom: 10px;
      cursor: pointer;

      &:after {
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -6px;
        content: '';
        width: 1px;
        height: 12px;
        background-color: #666;
      }

      a {
        color: #888;

        &:hover {
          color: var(--color_main);
        }
      }
    }
  }

  li:last-child {
    border-right: none;

    &:after {
      content: none;
    }
  }

  .copy_right {
    color: #4c4c4c;
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 1px;
    margin-bottom: 4px;

    a {
      color: #4c4c4c;

      &:hover {
        color: var(--color_main);
      }
    }
  }

  .author {
    color: #4c4c4c;
    font-size: 12px;
    cursor: default;

    &:hover {
      color: var(--color_main);
    }
  }
}</style>