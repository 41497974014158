<template>
    <div style="margin-left: 30px">
        <div style="height: 10px"></div>
        <div class="floor_title" data-v-1e6af222=""><h2 data-v-1e6af222=""><font data-v-1e6af222=""
                                                                                 style="background-color: rgb(139, 87, 42);">&nbsp; </font><span
                data-v-1e6af222="" style="color: rgb(139, 87, 42);">猜你喜欢</span><font data-v-1e6af222=""
                                                                                     style="background-color: rgb(139, 87, 42);">&nbsp; </font>
        </h2></div>
        <div class="goodgs">


            <div class="item" v-for="item in decorateData.data" @click="goGoodsDetail(item.defaultProductId)">
                <div class="left">
                    <img :src="item.goodsImage" style="  border-radius: 10px;overflow: hidden">
                </div>
                <div class="right">
                    <div class="goodsName" style="flex: 1">{{item.goodsName}}</div>
                    <div class="goodsPrice">¥{{item.goodsPrice}}</div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import {useRouter} from "vue-router";

    export default {
        name: "IndexList",
        props: {
            decorateData: Object,//装修的数据
            source: String,//来源，home:平台首页  store:店铺装修
        },
        setup() {


            const router = useRouter()
            const goGoodsDetail = productId => {
                let newWin = router.resolve({
                    path: "/goods/detail",
                    query: {
                        productId,
                    }
                });
                window.open(newWin.href, "_blank")
            };
            return {goGoodsDetail}
        }
    }
</script>

<style scoped lang="scss">
    .sub-header {
        width: 1200px;
        margin: 0 auto;
        font-size: 16px;
        font-weight: bold;
    }
    .floor_title{
        position: relative;
        margin-bottom: 15px;
        height: 35px;
        line-height: 35px;
        overflow: hidden;
        margin-top: 10px;
        h2{
            width: 100%;
            line-height: 35px;
            text-align: center;
            margin: 0 auto;
            font-size: 28px;
            color: #333;
            height: 35px;
            font{
                display: inline-block;
                width: 80px;
                height: 1px;
                background: #ca0a01;
                position: relative;
                top: 20px;
            }
            span{
                font-size: 24px;
                margin: 0 20px;
                color: #ca0a01;
                width: auto;
                min-width: 30px;
                height: 35px;
                line-height: 35px;
                text-indent: 3px;
                display: inline-block;
                font-weight: normal;
                vertical-align: middle;
            }
        }
    }

    .goodgs {
        display: flex;
        width: 1200px;
        margin: 0 auto;
        flex-wrap: wrap;

        .item {
            background-color: #F7F9FA;
            display: flex;
            border: 1px solid #F7F9FA;
            width: calc(33% - 20px);
            cursor: pointer;

            height: 160px;
            margin: 10px;
            margin-left: 0px;
            border-radius: 10px;


            .left {

                overflow: hidden;
                width: 160px;
                height: 160px;
                display: flex;
                border-radius: 10px;
                align-items: center;
                justify-content: center;

                img {

                    width: 150px;

                }
            }

            .right {
                display: flex;
                flex-direction: column;
                margin-left: 5px;
                padding: 10px;
                flex: 1;

                .goodsName {
                    font-size: 17px;
                    font-family: 微软雅黑;
                }

                .goodsPrice {
                    font-weight: bold;
                    color: #DBA35B;
                    font-size: 18px;
                }

            }
        }

        .item:hover {
            border: 1px solid #D2A96A;
            /*width: calc(33% - 20px - 2px);*/
            cursor: pointer;

            /*height: calc(160px - 2px);*/
            //box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        }
    }
</style>