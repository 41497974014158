// 头部搜索导航栏
<template>
    <div class="sld_home_top_search container">
        <div class="ld sld_home_top_search_left">
            <router-link tag="a" :to="`/index`" class="sld_logo_wrap flex_row_start_center">
                <img :src="configInfo.main_site_logo || defaultImg" :onerror='defaultImg' alt />
            </router-link>
        </div>
        <div class="flex_row_end_center">
            <div class="sld_seach_wrap">
                <div class="sld_seach_box ld" :style="{borderColor:'var('+color_main+')'}">
                    <div class="form" @submit.prevent :style="{backgroundColor:'var('+color_main+')'}">
                        <i class="iconfont iconsousuo" :style="{color:'var('+color_main+')'}"></i>
                        <input v-model="keyword" type="text" class="text" autocomplete="off" :placeholder="L['请输入关键词']"
                            @keyup.enter="search" ref="searchInput" @focus="inputFocus" @input="inputChange"
                            @blur="inputBlur" />
                        <input type="submit" :value="L['搜索']" class="button" @click="search" />
                    </div>
                    <div class="hot_search_wrap">
                        <div>
                            <template v-for="(item, index) in hotList.data" :key="index">
                                <a href="javascript:void(0)" @click="quickSearch(index, 'quick')"
                                    :title="tmpHotList[index]">{{ item }}</a>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="search_association" id="searchA" v-show="SAList.length && SAShow">
                    <div class="s_a_item" v-for="(item, index) in SAList" :key="index"
                        @mousedown="quickSearch(item.wordsContent, 'associ')">
                        <!-- 因为click事件与blur事件冲突，blur事件优先，于是换成mousedown -->
                        <div>{{ item.wordsContent }}</div>
                        <div>{{ item.searchGoodsNum }}{{ L['个商品'] }}</div>
                    </div>
                </div>
            </div>
            <div class="sld_cart_wrap">
                <dl class>
                    <dt class="ld cart_icon_text_wrap" @click="navtoCart" :style="{borderColor:'var('+color_main+')',background:pointColor.data[0]?'var('+color_halo+')':'#fff'}" @mouseover="mouseOver(0)"
                             @mouseleave="mouseLeave(0)">
                        <span class="iconfont icongouwuche" :style="{color:'var('+color_main+')'}"></span>
                        <router-link target="_blank" :to="`/cart/index`" :style="{color:'var('+color_main+')'}">{{ L['购物车'] }}</router-link>
                        <!-- 没有商品显示0 -->
                        <div class="cart_goods_num" :style="{background:'var('+color_price+')'}">{{
                            cartData.data && cartData.data.availableCartNum ?
                            cartData.data.availableCartNum : 0
                        }}
                        </div>
                    </dt>
                    <dd class="cart_more_view" @mouseover="mouseOver(0)"
                             @mouseleave="mouseLeave(0)">
                        <cart-model :color_main="color_main" :color_price="color_price"></cart-model>
                    </dd>
                </dl>
            </div>
        </div>
        <div v-if="searchBarFixed" class="container_header">
            <div class="container_header_box flex_row_between_center">
                <div class="sld_cart_wrap fixed_sld_cart_wrap">
                    <router-link tag="a" :to="`/index`" class="sld_logo_wrap flex_row_start_center">
                        <img :src="configInfo.main_site_logo || defaultImg" :onerror='defaultImg' alt />
                    </router-link>
                </div>
                <CategorySort type="row" :hideOn="hideOn" />
                <div class="arrow" @mousedown="navTo('/goods/Category')">>></div>
                <div class="flex_row_end_center">
                    <div class="sld_seach_wrap" :class="{ hideOn }">
                        <div class="sld_seach_box ld">
                            <div class="form">
                                <i v-if="hideOn" @click.stop="showHide" style="padding:11px;left:0;"
                                    class="iconfont iconsousuo2" :style="{color:'var('+color_main+')'}"></i>
                                <i v-else class="iconfont iconsousuo"></i>
                                <input v-model="keyword" type="text" class="text" autocomplete="off"
                                    style="color:rgb(153,153,153);" :placeholder="L['请输入关键词']" ref="searchInput"
                                    @focus="inputFocus" @blur="inputBlur" />
                                <input type=" submit" :value="L['搜索']" class="button" @mousedown="search" />
                            </div>
                        </div>
                    </div>
                    <div class="sld_cart_wrap">
                        <dl class>
                            <dt class="ld cart_icon_text_wrap" @click="navtoCart" :style="{borderColor:'var('+color_main+')',background:pointColor.data[1]?'var('+color_halo+')':'#fff'}" @mouseover="mouseOver(1)"
                             @mouseleave="mouseLeave(1)">
                                <span class="iconfont icongouwuche" :style="{color:'var('+color_main+')'}"></span>
                                <router-link target="_blank" :to="`/cart/index`" :style="{color:'var('+color_main+')'}">{{ L['购物车'] }}</router-link>
                                <!-- 没有商品显示0 -->
                                <div class="cart_goods_num" :style="{background:'var('+color_price+')'}">{{
                                    cartData.data && cartData.data.availableCartNum ?
                                    cartData.data.availableCartNum : 0
                                }}</div>
                            </dt>
                            <dd class="cart_more_view" @mouseover="mouseOver(1)"
                             @mouseleave="mouseLeave(1)">
                                <cart-model :color_main="color_main" :color_price="color_price"></cart-model>
                            </dd>
                        </dl>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive, getCurrentInstance, ref, watchEffect, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { ElMessage } from "element-plus";
import CartModel from '@/components/CartModel';
import CategorySort from '@/components/CategorySort2';

export default {
    name: "SldHomeTopSearch",
    props: {
        color_main: {
            type: String,
            default: '--color_main'
        },
        color_price:{
            type: String,
            default: '--color_price'
        },
        color_halo:{
            type: String,
            default: '--color_halo'
        },
    },
    components: {
        CartModel,
        CategorySort
    },
    setup() {
        const { proxy } = getCurrentInstance();
        const router = useRouter();
        const route = useRoute()
        const L = proxy.$getCurLanguage();
        const store = useStore();
        const cartData = reactive({ data: {} }); //获取vux的store中的购物车数据
        const logoUrl = "";
        const configInfo = ref(store.state.configInfo)
        const defaultImg = require('@/assets/common_top_logo.png')
        const keyword = ref(route.query.keyword);
        const searchUrl = require("../assets/header/magnify.png");
        const cartUrl = require("../assets/header/cart.png");
        const picture = require("../assets/picture.png");
        const goods_name = "";
        const searchBarFixed = ref(false);
        const hotList = reactive({ data: [] }); //热门搜索词列表
        const tmpHotList = ref([])
        const delGoodsCartIds = ref(''); //删除商品的id
        const SAList = ref([])
        const SAShow = ref(false)
        const hideOn = ref(true);
        const pointColor=reactive({
            data:[false,false]
        })

        onMounted(() => {
            cartData.data = store.state.cartListData;
            getInitData();
        })
        const getInitData = () => {
            proxy
                .$get("v3/system/front/setting/getSettings?names=hot_search_words")
                .then(res => {
                    if (res.state == 200) {
                        let tmp_data = res.data[0] ? res.data[0].split(",").filter(i => i != '') : [];
                        hotList.data = tmp_data;
                        tmpHotList.value = tmp_data
                        hotList.data = hotList.data.map(key => {
                            if (key.length > 10) {
                                return key.substring(0, 10) + '...'
                            } else {
                                return key
                            }
                        })
                    }
                });
        };

        const cart_num = "";
        watchEffect(() => {
            if (route.query.keyword) {
                keyword.value = route.query.keyword;
            } else {
                keyword.value = "";
            }
            cartData.data = store.state.cartListData;
        });

        //搜索事件
        const search = () => {
            proxy.$refs.searchInput.style.color = 'rgb(153,153,153)'

            if (keyword.value) {
                router.push({
                    path: `/goods/list`,
                    query: { keyword: keyword.value }
                });
            }
        };

        const searchAssociation = (input) => {
            proxy.$get('v3/goods/front/goods/searchWords/list', {
                keyWord: input
            }).then(res => {
                if (res.state == 200) {
                    SAList.value = res.data
                }
            })
        };

        const inputChange = (e) => {
            let input = e.target.value
            SAShow.value = true

            if (input) {
                searchAssociation(input)
            } else {
                SAList.value = []
            }
        };

        //热门搜索事件
        const quickSearch = (val, type) => {
            if (type == "quick") {
                router.push({ path: `/goods/list`, query: { keyword: tmpHotList.value[val] } });
            } else {
                if (val == route.query.keyword) {
                    keyword.value = val
                } else {
                    router.push({ path: `/goods/list`, query: { keyword: val } });

                }
            }
        };

        const showHide = () => {
            hideOn.value = false;
            proxy.$refs.searchInput.focus();
        };

        const inputFocus = () => {
            hideOn.value = false;
            proxy.$refs.searchInput.style.color = '#333'
            SAShow.value = true
            if (keyword.value && SAList.value.length == 0) {
                searchAssociation(keyword.value)
            }
        };

        const inputBlur = () => {
            SAShow.value = false
            hideOn.value = true;
        };

        //滚动事件
        const handleScroll = () => {
            var height = 200;
            var scrollTop =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop;
            if (scrollTop > height) {
                searchBarFixed.value = true;
            } else {
                searchBarFixed.value = false;
            }
        };

        if (route.path != '/goods/detail') {
            window.addEventListener("scroll", handleScroll);
        }

        //删除商品 curCartItem: 当前商品信息
        const delCartGoods = (curCartItem) => {
            if (store.state.loginFlag) {
                //已登录
                delGoodsCartIds.value = curCartItem.cartId;
            } else {
                //未登录
                delGoodsCartIds.value = curCartItem.goodsId;
            }
            confirmDelGoodsModel();
        };
        //确定删除商品
        const confirmDelGoodsModel = () => {
            if (store.state.loginFlag) {
                // 已登录
                let params = {
                    cartIds: delGoodsCartIds.value,
                };
                proxy
                    .$post("v3/business/front/cart/deleteCarts", params)
                    .then((res) => {
                        if (res.state == 200) {
                            cartData.data = res.data;
                            ElMessage.success(L["删除成功！"]);
                        } else {
                            ElMessage.error(res.msg);
                        }
                    });
            } else {
                // 未登录
                //单个商品删除
                cartData.data.storeCartGroupList.map((storeItem, storeIndex) => {
                    storeItem.promotionCartGroupList.map((promotItem) => {
                        promotItem.cartList.map((cartItem, cartIndex) => {
                            if (cartItem.goodsId == delGoodsCartIds.value) {
                                promotItem.cartList.splice(cartIndex, 1);
                            }
                        });
                        if (promotItem.cartList.length == 0) {
                            storeItem.promotionCartGroupList = [];
                        }
                        if (storeItem.promotionCartGroupList.length == 0) {
                            cartData.data.storeCartGroupList.splice(storeIndex, 1);
                        }
                    });
                });
                ElMessage.success(L["删除成功！"]);
            }
            store.commit("updateCartListData", cartData.data);
        };

        const navTo = (url) => {
            router.push(url);
        };

        const navtoCart = () => {
            let newWin = router.resolve({
                path: '/cart/index'
            })
            window.open(newWin.href, '_blank');
        };

        // 移入
        const mouseOver = (type)=>{
            if(!pointColor.data[type]){
                pointColor.data[type] = true
            }
        }
        // 移出
        const mouseLeave = (type)=>{
            if(pointColor.data[type]){
                pointColor.data[type] = false

            }
        }

        return {
            L,
            logoUrl,
            searchUrl,
            cartUrl,
            picture,
            goods_name,
            hotList,
            cart_num,
            searchBarFixed,
            keyword,
            search,
            quickSearch,
            delGoodsCartIds,
            delCartGoods,
            cartData,
            configInfo,
            defaultImg,
            inputFocus,
            tmpHotList,
            searchAssociation,
            SAList,
            inputChange,
            inputBlur,
            SAShow,
            navTo,
            hideOn,
            showHide,
            navtoCart,
            mouseOver,
            mouseLeave,
            pointColor
        }
    }
};
</script>

<style lang="scss" scoped>
.sld_home_top_search {
    height: 99px;
    position: relative;
    z-index: 12;
    width: 1210px;
    margin: 0 auto 15px;
    padding-left: 0px;
    padding-right: 0px;

    .container_header {
        width: 100%;
        position: fixed;
        background-color: #fff;
        left: 0;
        right: 0;
        top: 0;
        z-index: 999;

        box-shadow: 0 0 3px 0 rgb(0 0 0 / 36%);

        .sld_cart_wrap {
            flex-shrink: 0;
        }

        .sld_seach_wrap,
        .sld_cart_wrap {
            margin: 5px;
            position: relative;
        }

        .flex_row_end_center {
            .sld_seach_wrap {
                margin: 6px 12px 5px 0;
                position: relative;
            }

            .sld_cart_wrap {
                margin: 5px 30px 5px 24px;
                position: relative;
            }
        }

        .container_header_box {
            width: 1200px;
            margin: 0 auto;

            .fixed_sld_cart_wrap {

                a {
                    width: 100%;
                    height: 100%;

                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
            }

            .arrow {
                position: relative;
                right: 10px;
                cursor: pointer;
                font-size: 16px;
                letter-spacing: -3px;

                &:hover {
                    color: var(--color_main);
                }
            }
        }
    }

    .container_header {
        -webkit-animation: searchTop .5s ease-in-out;
        animation: searchTop .5s ease-in-out
    }

    @-webkit-keyframes searchTop {
        0% {
            top: -50px
        }

        to {
            top: 0
        }
    }

    @keyframes searchTop {
        0% {
            top: -50px
        }

        to {
            top: 0
        }
    }

    &:before {
        display: table;
        content: " ";
    }

    .sld_home_top_search_left {
        position: relative;
        float: left;
        width: 331px;
        margin-top: 32px;
        margin-left: 20px;

        .sld_logo_wrap {
            display: block;
            width: 160px;
            height: 60px;

            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
    }

    .sld_seach_wrap {
        float: left;
        width: 528px;
        padding-top: 4px;
        margin-top: 28px;
        position: relative;

        .sld_seach_box {
            width: 532px;
            z-index: 11;
            height: 41px;
            margin-bottom: 3px;
            border: 1px solid var(--color_main);
            border-radius: 19px;

            .form {
                display: flex;
                overflow: hidden;
                height: 39px;
                background-color: var(--color_main);
                border: none;
                border-radius: 19px 15px 15px 19px;

                .iconfont {
                    position: absolute;
                    left: 12px;
                    top: 50%;
                    transform: translateY(-50%);
                    // color: #CBD4CF;
                    color: var(--color_main);
                    font-size: 18px;
                }

                .text {
                    width: 442px;
                    height: 39px;
                    line-height: 20px;
                    color: rgb(153, 153, 153);
                    font-family: arial, "\5b8b\4f53";
                    font-size: 13px;
                    background-color: #fff;
                    background-position: 0 -360px;
                    background-color: #fff;
                    background-repeat: repeat-x;
                    padding: 5px 5px 5px 40px;
                    outline: none;
                    border: none;
                    -webkit-appearance: none;
                    -webkit-border-radius: 0;
                }

                input {
                    margin: 0;
                    padding: 0;
                    height: 39px;
                    border: 0;
                }

                input::placeholder {
                    font-size: 12px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: rgb(153, 153, 153);
                }

                ::-webkit-input-placeholder {
                    margin-left: 20px;
                    font-size: 12px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: rgb(153, 153, 153);
                }

                /* 使用webkit内核的浏览器 */
                :-moz-placeholder {
                    font-size: 12px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: rgb(153, 153, 153);
                }

                /* Firefox版本19+ */
                :-ms-input-placeholder {
                    font-size: 12px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: rgb(153, 153, 153);
                }

                /* IE浏览器 */

                .button {
                    width: 86px;
                    background: var(--color_main);
                    background: none;
                    font-size: 16px;
                    font-weight: 600;
                    color: #fff;
                    // float: right;
                    cursor: pointer;
                    text-align: center;
                    border-top-right-radius: 15px;
                    border-bottom-right-radius: 15px;
                }
            }

            .hot_search_wrap {
                height: 30px;
                line-height: 30px;
                color: #999;
                overflow: hidden;
                padding-left: 5px;
                padding-right: 5px;

                strong {
                    float: left;
                    font-weight: 400;
                }

                a {
                    position: relative;
                    color: #666;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    word-break: break-all;

                    &:before {
                        display: block;
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 50%;
                        margin-top: -6px;
                        width: 1px;
                        height: 12px;
                        background-color: rgb(204, 204, 204);
                    }

                    &:nth-child(1) {
                        &:before {
                            content: none;
                        }
                    }

                    &:link,
                    &:visited {
                        float: left;
                        padding-left: 10px;
                        padding-right: 10px;
                    }

                    &:hover {
                        color: var(--color_main);
                    }
                }
            }
        }

        .search_association {
            background: #fff;
            position: absolute;
            top: 46px;
            overflow: hidden;
            position: absolute;
            left: 14px;
            width: 502px;
            border: 1px solid #CCC;
            background: #fff;
            z-index: 99;

            .s_a_item {
                display: flex;
                justify-content: space-between;
                overflow: hidden;
                padding: 1px 5px;
                line-height: 24px;
                cursor: pointer;
                font-size: 12px;
                -webkit-font-smoothing: antialiased;
                color: #666;

                div:first-child {
                    width: 250px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }

                div:last-child {
                    overflow: hidden;
                    color: #aaa;
                }

                &:hover {
                    background-color: rgb(255, 233, 188);
                }
            }
        }

        &.hideOn {
            width: 44px;
            border-radius: 19px;
            overflow: hidden;

            .sld_seach_box {
                position: relative;
                left: 10px;
                width: 44px;
                border-color: #FFFFFF;
                overflow: hidden;

                .iconfont {
                    color: var(--color_main);
                    cursor: pointer;
                }
            }
        }
    }
}

.sld_cart_wrap {
    float: right;
    position: relative;
    z-index: 99;
    width: 134px;
    height: 39px;
    margin-top: 31px;
    margin-right: 33px;
    margin-left: 60px;

    // &:hover {
    //     dl {
    //         dt {
    //             background-color: var(--color_halo);
    //         }
    //     }
    // }

    dl {
        margin-bottom: 0px;

        .cart_goods_num {
            font: 11px/16px Verdana;
            color: #fff;
            background: var(--color_price);
            text-align: center;
            display: inline-block;
            height: 16px;
            min-width: 16px;
            border: none 0;
            border-radius: 8px;
            margin-left: 9px;
        }

        dt {
            position: absolute;
            z-index: 3;
            width: 134px;
            height: 39px;
            border: 1px solid var(--color_main);
            border-radius: 19px;
            background-color: #fff;
            cursor: pointer;
            font-weight: 400;

            .iconfont {
                line-height: 36px;
                color: var(--color_main);
                font-size: 17px;
                font-weight: 600;
                font-family: "iconfont" !important;
                font-style: normal;
                vertical-align: bottom;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                margin-left: 18px;
                margin-right: 10px;
            }

            &.cart_icon_text_wrap {

                a {
                    color: var(--color_main);
                    font-size: 14px;
                    line-height: 36px;
                }
            }
        }

        dd {
            .cart_goods {
                dl {
                    padding-top: 8px;
                }

                dd {
                    &.cart_goods_price {
                        position: static;

                        em {
                            margin-right: 6px;
                            width: auto;
                            color: #666;

                            &:nth-child(1) {
                                display: block;
                                font-weight: 600;
                            }

                            &:nth-child(2) {
                                display: block;
                                text-align: right;
                                margin-top: 6px;
                            }
                        }
                    }
                }
            }
        }
    }

    dd {
        position: absolute;
        top: 38px;
        right: 0;
        width: 355px;
        border: 1px solid #e3e3e3;
        background: #fff;
        z-index: 1;
    }

    &:hover .cart_more_view {
        display: inline-block;
    }
}

.cart_more_view {
    display: none;

    .empty_cart {
        width: 100%;
        position: relative;

        .empty_cart_line {
            position: absolute;
            width: 163px;
            right: 0;
            height: 2px;
            top: -2px;
            z-index: 999;
            background: #fff;
        }

        .empty_cart_txt {
            padding: 10px;
            color: #999;
        }
    }
}

.ld {
    position: relative;
    zoom: 1;
}
</style>