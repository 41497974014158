// 头部登陆注册导航栏
<template>
  <div class="header_wrap">
    <div class="header">
      <div class="header_left">
        <span class="hello">{{ L['您好，欢迎来到'] }}{{ configInfo.data.basic_site_name }}</span>
        <template v-if="loginFlag">
          <span class="register h1" @click="goToByPush('/member/index')">{{ memberInfo.memberNickName ||
            memberInfo.memberName }}</span>
          <span class="register h1 login_out" @click="loginOut()">[{{ L['退出'] }}]</span>
        </template>

        <template v-if="!loginFlag">
          <span class="register h1" @click="goToByPush('/login')">{{ L['登录'] }}</span>
          <span class="register h1" @click="goToByPush('/register')">{{ L['注册'] }}</span>
        </template>
      </div>
      <div class="header_right">
        <ul>
          <li @click="goToByPush('/index', {})">
            <div class="li_item">{{ L['商城首页'] }}</div>
          </li>
          <li>
            <div class="has_more li_item">
              {{ L['我的订单'] }}
              <div class="li_item_more">
                <a href="javascript:void(0)" class="li_item_more_item"
                  @click="goToByPush('/member/order/list', { 'orderState': 10 })">{{ L['待付款订单'] }}</a>
                <a href="javascript:void(0)" class="li_item_more_item"
                  @click="goToByPush('/member/order/list', { 'orderState': 30 })">{{ L['待收货订单'] }}</a>
                <a href="javascript:void(0)" class="li_item_more_item" @click="goToByPush('/member/order/evaluation')">{{
                  L['待评价订单'] }}</a>
              </div>
            </div>
          </li>
          <li @click="goToByPush('/member/index')">
            <div class="li_item">{{ L['个人中心'] }}</div>
          </li>
          <li>
            <div class="has_more li_item">
              {{ L['我的收藏'] }}
              <div class="li_item_more">
                <a href="javascript:void(0)" class="li_item_more_item" @click="goToByPush('/member/collect')">{{
                  L['商品收藏'] }}</a>
                <a href="javascript:void(0)" class="li_item_more_item" @click="goToByPush('/member/footprint')">{{
                  L['我的足迹'] }}</a>
              </div>
            </div>
          </li>
          <li>
            <div class="has_more li_item">
              {{ L['我的账户'] }}
              <div class="li_item_more">
                <a href="javascript:void(0)" class="li_item_more_item" @click="goToByPush('/member/coupon')">{{
                  L['我的优惠券'] }}</a>
                <a href="javascript:void(0)" class="li_item_more_item" @click="goToByPush('/member/balance')">{{
                  L['我的余额'] }}</a>
              </div>
            </div>
          </li>
          <li @click="goToByPush('/article')">
            <div class="li_item">{{ L['服务中心'] }}</div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { getCurrentInstance, reactive } from "vue";
export default {
  name: "NavTopBar",
  setup() {
    const { proxy } = getCurrentInstance();
    const store = useStore();
    const L = proxy.$getCurLanguage();
    const memberInfo = store.state.memberInfo;
    const loginFlag = store.state.loginFlag;
    const router = useRouter();
    const configInfo = reactive({ data: {} })
    //去注册
    const goRegister = () => {
      router.push({
        path: `/register`,
        query: {
          redirect: encodeURIComponent(window.location.href)
        }
      });
    };
    // 获取系统配置信息
    const getSystemConfigInfo = () => {
      proxy
        .$get("v3/system/front/setting/getSettings", {
          names: "main_site_logo,main_user_center_logo,main_user_logon_bg,main_user_register_bg,pc_home_bottom_adv,main_user_forget_password_bg,basic_site_name," +
            "basic_site_icp,basic_site_copyright,basic_site_technical_support,right_toolbar_app_qrcode_image,right_toolbar_wechat_qrcode_image"
        })
        .then(res => {
          if (res.state == 200) {
            configInfo.data = {
              main_site_logo: res.data[0],
              main_user_center_logo: res.data[1],
              main_user_logon_bg: res.data[2],
              main_user_register_bg: res.data[3],
              pc_home_bottom_adv: res.data[4],
              main_user_forget_password_bg: res.data[5],
              basic_site_name: res.data[6],
              basic_site_icp: res.data[7],
              basic_site_copyright: res.data[8],
              basic_site_technical_support: res.data[9],
              right_toolbar_app_qrcode_image: res.data[10],
              right_toolbar_wechat_qrcode_image: res.data[11]
            }
            store.commit('updateConfigInfo', configInfo.data);
          }
        })
        .catch(() => {
          //异常处理
        });
    };
    getSystemConfigInfo();
    //前往具体的页面，type为具体的页面地址，param为参数对象
    const goToByPush = (type, param = {}) => {
      router.push({
        path: type,
        query: param
      });
    };
    //退出登录
    const loginOut = () => {
      store.commit("clearAllData"); //清除数据
      proxy.$socket.disconnect()
      goToByPush("/login", {});
    };
    return { L, goRegister, memberInfo, loginOut, loginFlag, goToByPush, configInfo };
  }
};
</script>

<style lang="scss" scoped>
.header_wrap {
  width: 100%;
  height: 36px;
  background-color: #333;
  display: flex;
  align-items: center;
  justify-content: center;

  .header {
    width: 1210px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .header_left {
      display: flex;
      height: 100%;
      line-height: 36px;
      color: #CCC;
      font-size: 12px;

      .hello {
        margin-right: 20px;
        color: #CCC;
      }

      .h1 {
        margin: 0 5px;
        cursor: pointer;

        &:hover {
          color: #FFF;
        }
      }
    }

    .header_right {
      height: 100%;

      ul {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;

        .personal_center {
          width: 121px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        li {
          float: left;
          height: 12px;
          text-align: center;
          border-right: 1px solid #5c5c5c;
          padding-left: 14px;
          padding-right: 14px;

          .li_item {
            position: relative;
            font-size: 12px;
            height: 33px;
            line-height: 12px;
            color: #CCC;
            cursor: pointer;

            &:hover {
              color: #FFF;

              &.has_more {
                &:before {
                  border-top-color: #FFF;
                }
              }

              .li_item_more {
                display: block;
              }
            }

            &.has_more {
              padding-right: 12px;

              &:before,
              &:after {
                position: absolute;
                right: -2px;
                display: block;
                width: 0;
                height: 0;
                content: " ";
                border: 4px solid transparent;
                border-radius: 2px;
              }

              &:before {
                top: 3px;
                border-top: 5px solid #CCC;
              }

              &:after {
                top: 1px;
                border-top: 5px solid #333;
              }
            }

            .li_item_more {
              display: none;
              position: absolute;
              width: 94px;
              top: 24px;
              left: 50%;
              transform: translateX(-50%);
              z-index: 999;
              background: #fff;
              box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
              padding: 5px 6px;

              &:before,
              &:after {
                position: absolute;
                top: -11px;
                left: 50%;
                transform: translateX(-50%);
                -webkit-transform: translateX(-50%);
                display: block;
                content: " ";
                width: 0;
                height: 0;
                border: 5px solid transparent;
                border-bottom: 6px solid #dedede;
              }

              &:after {
                top: -10px;
                border-bottom: 6px solid #fff;
              }

              .li_item_more_item {
                position: relative;
                display: block;
                height: 38px;
                line-height: 38px;
                color: #666;
                font-size: 12px;
                text-align: center;

                &:before {
                  position: relative;
                  top: 0;
                  left: 50%;
                  transform: translateX(-50%);
                  z-index: 9;
                  content: '';
                  display: block;
                  width: 58px;
                  height: 0.5px;
                  background-color: #ddd;
                }

                &:hover {
                  color: var(--color_main);
                }
              }

              .li_item_more_item:nth-child(1) {
                &:before {
                  display: none;
                }
              }
            }
          }

          &:last-child {
            border-right-width: 0;
            padding-right: 0;
          }
        }
      }
    }
  }
}
</style>