<template>
    <NavTopBar />
    <SldHomeTopSearch color_main="--color_integral_main" color_price="--color_integral_main" color_halo="--color_integral_halo"/>
    <NavCatHeader />
    <div class="bottom_line"></div>

    <router-view></router-view>
    <FooterService color="--color_integral_main"/>
    <FooterLink />
</template>

<script>
    import NavTopBar from "../../../components/NavTopBar";
    import FooterService from "../../../components/FooterService";
    import FooterLink from "../../../components/FooterLink";
    import SldHomeTopSearch from "@/components/SldHomeTopSearch";
    import NavCatHeader from "@/components/NavCatHeader";
    export default {
        name: 'GoodsDetailHeader',
        components: {
            NavTopBar,
            FooterService,
            FooterLink,
            SldHomeTopSearch,
            NavCatHeader
        },
        setup() { }
    }
</script>