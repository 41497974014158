<!--
 * @Author: your name
 * @Date: 2020-12-29 16:05:36
 * @LastEditTime: 2021-01-11 10:47:15
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /java-pc/src/components/NavCatHeader.vue
-->
<template>
  <div class="nav_cat">
    <div class="header">

      <nav style="width:auto;flex-shrink:0;">
        <li v-if="showIndex"><a :class="{ active: indexAct }" href="/">{{ L['首页'] }}</a></li>
      </nav>
      <CategorySort type="row" />
      <div class="arrow" @mousedown="navTo('/goods/Category')">>></div>
      <nav v-if="navList.data.length > 0" class="nav_list">
        <template v-for="(item, index) in navList.data" :key="index">
          <li v-if="index < 3">
            <a href="javascript:void(0)" @click="navClick(item)">{{ item.navName }}</a>
          </li>
        </template>
      </nav>
    </div>
  </div>
</template>

<script>
import { useRouter, useRoute } from "vue-router"; //引入路由
import { reactive, getCurrentInstance, onMounted, ref } from "vue";
import CategorySort from './CategorySort'
export default {
  name: "NavCatHeader",
  components: {
    CategorySort
  },
  setup() {
    const router = useRouter(); //使用路由
    const route = useRoute()
    const showIndex = ref(true)
    const navList = reactive({ data: [] });
    const { proxy } = getCurrentInstance();
    const L = proxy.$getCurLanguage();
    const sortUrl = require("@/assets/header/sort.png");
    const indexAct = ref(false);
    const toGoodsList = () => {
      router.push("/goods/Category"); //push产品分类路由
    };
    const getNavData = () => {
      proxy.$get("v3/system/front/navigation/list").then(res => {
        if (res.state == 200) {
          navList.data = res.data;
        }
      });
    };
    const navClick = val => {
      if (val.data) {
        proxy.$diyNavTo(JSON.parse(val.data.replace(/&quot;/g, "\"")));
      }
    };
    const navTo = (url) => {
      router.push(url);
    };
    onMounted(() => {
      let path = route.path;
      if (path == '/index') {
        indexAct.value = true;
      }
      let dom = document.getElementById('category_sort')
      if (dom) {
        if (path == '/index') {
          dom.style.display = 'block'
          showIndex.value = false
        } else if (path == '/goods/Category') {
          dom.style.display = 'none'
        }
      }
      getNavData();
    })

    return { indexAct, navList, sortUrl, toGoodsList, navClick, showIndex, navTo, L };
  }
};
</script>

<style lang="scss" scoped>
.nav_cat {
  width: 1210px;
  height: auto;
  margin: 0 auto;
  overflow-x: clip;
}

.header {
  width: 1210px;
  height: 45px;
  margin: 0 auto;
  display: flex;
  overflow: visible;

  .product_sort {
    position: relative;
    overflow: visible;
    width: 187px;
    height: 100%;
    background: var(--color_vice_bg);
    color: #fff;
    font-size: 16px;
    letter-spacing: 3px;
    line-height: 30px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    cursor: pointer;
    box-sizing: border-box;
    padding: 0 50px 0 26px;

    &:hover {
      #category_sort {
        display: block;
      }
    }

    img {
      width: 12px;
      height: 12px;
    }
  }

  .arrow {
    position: relative;
    top: 14px;
    height: 20px;
    font-size: 16px;
    margin-right: 14px;
    letter-spacing: -3px;
    cursor: pointer;

    &:hover {
      color: var(--color_main);
    }
  }

  .nav_list {
    position: relative;
    flex-shrink: 0;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      z-index: 9;
      width: 1px;
      height: 14px;
      background: #ccc;
    }
  }

  nav {
    display: flex;
    align-items: center;
    line-height: 30px;
    overflow: hidden;
    flex-wrap: wrap;
    margin-left: 5px;
    padding-left: 5px;

    a {
      display: inline-block;
      width: max-content;
      height: 45px;
      line-height: 45px;
      color: #333333;
      font-size: 14px;
      font-weight: 700;
      margin-left: 10px;
      margin-right: 10px;
      padding-left: 6px;
      padding-right: 6px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }

    .active,
    a:hover {
      color: var(--color_main);
      border-bottom: 3px solid var(--color_main);
    }
  }
}
</style>
