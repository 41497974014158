<template>
     <!-- left tab -->
     <div class="fixed_tab" v-if="route.name != 'serviceChat'">
          <img v-if="top.show_switch && top.imgUrl" class="fixed_banner" :src="top.imgUrl" @click="diyNavTo(top)" />
          <div class="fixed_list">
               <div class="fixed_item flex_column_center_center" @click="operate('service')">
                    <i class="iconfont iconwangyeyoucegudingtubiao_lianxikefu"></i>
                    <span class="fixed_title">{{ L['联系客服'] }}</span>
               </div>
               <div class="fixed_item flex_column_center_center" v-if="config.right_toolbar_app_qrcode_image">
                    <i class="iconfont iconwangyeyoucegudingtubiao_saomiaoxiazai"></i>
                    <span class="fixed_title">{{ L['下载'] }} APP</span>
                    <div class="fixed_cover">
                         <div class="fixed_cover_main flex_column_center_center">
                              <img :src="config.right_toolbar_app_qrcode_image" />
                              <div>{{ L['下载'] }}<span>{{ config.basic_site_name }}</span>APP</div>
                         </div>
                    </div>
               </div>
               <div class="fixed_item flex_column_center_center" v-if="config.right_toolbar_wechat_qrcode_image">
                    <i class="iconfont iconwangyeyoucegudingtubiao_weixin"></i>
                    <span class="fixed_title">{{ L['关注微信'] }}</span>
                    <div class="fixed_cover">
                         <div class="fixed_cover_main flex_column_center_center">
                              <img :src="config.right_toolbar_wechat_qrcode_image" />
                              <div class="scan">{{ L['扫码关注'] }}<span>【{{ config.basic_site_name }}】</span>{{ L['微信公众号'] }}
                              </div>
                         </div>
                    </div>
               </div>
               <div class="fixed_item flex_column_center_center" @click="backTop">
                    <i class="iconfont iconwangyeyoucegudingtubiao_fanhuidingbu"></i>
                    <span class="fixed_title">{{ L['返回顶部'] }}</span>
               </div>
          </div>
     </div>
     <!-- left tab -->
</template>

<script>
import { getCurrentInstance, ref } from 'vue';
import { onMounted, watchEffect } from '@vue/runtime-core';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';

export default {
     setup(props) {
          const { proxy } = getCurrentInstance();
          const L = proxy.$getCurLanguage();
          const route = useRoute();
          const router = useRouter();
          const store = useStore();
          const config = ref(store.state.configInfo);
          const memberInfo = ref(store.state.memberInfo);
          const searchBarFixed = ref(false);
          const top = ref({});

          onMounted(() => {
               getAdv();
          })

          const getAdv = () => {
               proxy.$get('v3/system/front/pcDeco/firstAdv', {
                    type: 3
               }).then(res => {
                    if (res.state == 200) {
                         top.value = JSON.parse(res.data.data.replace(/&quot;/g, "\""));
                    }
               })
          }

          const operate = (type) => {
               let newWin
               switch (type) {
                    case 'service': {
                         let chatInfo = {
                              storeId: 0,
                              vendorAvatar: require('../assets/adminLogo.png'),
                              storeName: L['平台客服'],
                              showData: {},
                              source: ' 右侧悬浮框客服处'
                         }
                         store.commit('saveChatBaseInfo', chatInfo)
                         newWin = router.resolve({
                              path: '/service',
                              query: {
                                   vid: 0
                              }
                         })
                         break
                    }
               }
               window.open(newWin.href, '_blank')
          };

          const backTop = () => {
               window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
               })
          };

          const diyNavTo = (val) => {
               proxy.$diyNavTo(val)
          };

          watchEffect(() => {
               memberInfo.value = store.state.memberInfo;
               window.onscroll = (e) => {
                    var height = 600;
                    var scrollTop =
                         window.pageYOffset ||
                         document.documentElement.scrollTop ||
                         document.body.scrollTop;
                    if (scrollTop > height) {
                         searchBarFixed.value = true;
                    } else {
                         searchBarFixed.value = false;
                    }
               }
          });

          return {
               route,
               top,
               config,
               memberInfo,
               searchBarFixed,
               operate,
               backTop,
               diyNavTo,
               L
          }
     }
}

</script>

<style lang="scss">
.fixed_tab {
     position: fixed;
     right: 10px;
     top: 50%;
     transform: translateY(-50%);
     z-index: 1999;

     .fixed_banner {
          width: 80px;
          // height: 150px;
          margin-bottom: 2px;
          cursor: pointer;
     }

     .fixed_list {
          width: 81px;
          padding-left: 5px;
          padding-right: 5px;
          background: #fff;
          box-shadow: 0 0 18px rgb(0 0 0 / 10%);
     }

     .fixed_item {
          position: relative;
          font-size: 12px;
          text-align: center;
          border-top: 1px solid #e7e7e7;
          padding: 12px 10px;
          cursor: pointer;

          &:nth-child(1) {
               border-top: none;
          }

          .iconfont {
               font-size: 26px;
          }

          .fixed_title {
               margin-top: 6px;
          }

          &:hover {
               .fixed_cover {
                    display: block;
               }
          }

          .fixed_cover {
               display: none;
               position: absolute;
               left: 0;
               top: 0;
               transform: translateX(-100%);
               z-index: 9;

               &:hover {
                    display: block;
               }

               .fixed_cover_main {
                    background-color: #fff;
                    border: 1px solid #efefef;
                    border-radius: 2px;
                    margin-right: 14px;
                    padding: 20px;

                    img {
                         width: 105px;
                         height: 105px;
                         margin-bottom: 14px;
                    }

                    div {
                         min-width: 120px;
                         white-space: pre-wrap;
                         line-height: 22px;

                         &.scan {
                              min-width: 180px;
                         }

                         span {
                              margin-left: 4px;
                              margin-right: 4px;
                         }
                    }
               }
          }
     }
}</style>
