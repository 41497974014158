<template>
  <fixedTab></fixedTab>
  <router-view />
</template>

<script>
import { getCurrentInstance,onMounted } from 'vue'
import fixedTab from "@/components/fixedTab.vue";
export default {
  name: 'App',
  components: {
    fixedTab
  },
  setup(){
    const { proxy } = getCurrentInstance()
    // 获取系统配置信息
    const getSystemConfigInfo = () => {
      proxy.$get("v3/system/front/setting/getSettings", {
        names: "pc_browser_icon"
      }).then(res => {
        if (res.state == 200) {
            var link = document.querySelector("link[rel*='icon']") ||  document.createElement('link');
            link.type = 'image/x-icon';
            link.rel = 'shortcut icon';
            link.href = res.data[0]?res.data[0]:location.origin+'/favicon.ico';
            document.getElementsByTagName('head')[0].appendChild(link);
        }
      })
    };
    onMounted(() => {
      getSystemConfigInfo()
    })
  }
}
</script>

<style lang="scss">
  @import "./style/reset.scss";
  @import "./style/base.scss";
  //放大镜位置
  .mouse-cover-canvas {
    position: absolute;
    top: 173px !important;
    left: 740px !important;
  }
</style>
