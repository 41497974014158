/*
* 语言文件 zh：中文
* */
export const lang_zn = {
    //首页公共头部最顶部
    '您好，欢迎来到': '您好，欢迎来到',
    '退出': '退出',
    '注册': '注册',
    '商城首页': '商城首页',
    '待支付订单': '待支付订单',
    '待付款订单': '待付款订单',
    '待收货订单': '待收货订单',
    '待评价订单': '待评价订单',
    '个人中心': '个人中心',
    '商品收藏': '商品收藏',
    '店铺收藏': '店铺收藏',
    '我的账户': '我的账户',
    '服务中心': '服务中心',
    '全部分类': '全部分类',

    //登录页面
    '手机登录': '手机登录',
    '账号登录': '账号登录',
    '还没注册？': '还没注册？',
    '去注册': '去注册',
    '账号为4~16位字母、数字或下划线': '账号为4~16位字母、数字或下划线',
    '登录': '登录',
    '绑定': '绑定',
    '请输入密码': '请输入密码',
    '立即注册': '立即注册',
    '忘记密码': '忘记密码',
    '请输入账号/手机号': '请输入账号/手机号',
    '请输入手机号': '请输入手机号',
    '请输入验证码': '请输入验证码',
    '获取验证码': '获取验证码',
    's后获取': 's后获取',
    '请输入正确的账号/手机号': '请输入正确的账号/手机号',
    '绑定手机号': '绑定手机号',
    //忘记密码页面
    '想起密码？': '想起密码？',
    '去登录': '去登录',
    '找回密码': '找回密码',

    //注册页面
    '已有账号？': '已有账号？',
    '已有账号，去登录': '已有账号，去登录',
    '注册账号': '注册账号',
    '请输入图形验证码': '请输入图形验证码',
    '我同意': '我同意',
    '《用户注册协议及隐私政策》': '《用户注册协议及隐私政策》',
    '《用户注册协议》': '《用户注册协议》',
    '《隐私政策》': '《隐私政策》',

    //注册协议页面
    '我已知悉？': '我已知悉？',

    //文章，商品分类
    '暂无数据': '暂无数据',
    '首页': '首页',
    '文章': '文章',
    '文章分类': '文章分类',
    '最新文章': '最新文章',
    '搜索': '搜索',
    '默认': '默认',
    '人气': '人气',
    '成交量': '成交量',
    '请输入店铺名称': '请输入店铺名称',

    //商品列表
    '综合': '综合',
    '评论数': '评论数',
    '商品类型': '商品类型',
    '仅显示有货': '仅显示有货',
    '仅平台自营': '仅平台自营',
    '分类': '分类',
    '筛选结果': '筛选结果',
    '包含分类': '包含分类',
    '清空': '清空',
    '更多选项': '更多选项',

    //商品详情页面
    '自营': '自营',
    '联系客服': '联系客服',
    '已关注': '已关注',
    '关注店铺': '关注店铺',
    '价格': '价格',
    '销量': '销量',
    '优惠券': '优惠券',
    '促销': '促销',
    '查看详情': '查看详情',
    '配送至': '配送至',
    '请选择地址': '请选择地址',
    '运费': '运费',
    '免运费': '免运费',
    '数量': '数量',
    '库存': '库存',
    '立即购买': '立即购买',
    '购物车': '购物车',
    '已收藏': '已收藏',
    '收藏': '收藏',
    '分享': '分享',
    '看了又看': '看了又看',
    '店铺等级': '店铺等级',
    '商品评价': '商品评价',
    '物流评价': '物流评价',
    '售后服务': '售后服务',
    '进入店铺': '进入店铺',
    '店铺推荐': '店铺推荐',
    '高': '高',
    '低': '低',
    '中': '中',
    '取消关注': '取消关注',
    '热门收藏': '热门收藏',
    '已售': '已售',
    '商品详情': '商品详情',
    '评价': '评价',
    '商品服务': '商品服务',
    '店铺热销': '店铺热销',
    '手机下单': '手机下单',
    '加入购物车': '加入购物车',
    '品牌': '品牌',
    '查看全部': '查看全部',
    '收起全部': '收起全部',
    '评分': '评分',
    '好评率': '好评率',
    '全部': '全部',
    '有图': '有图',
    '好评': '好评',
    '中评': '中评',
    '差评': '差评',
    '商品已下架': '商品已下架',
    '库存不足': '库存不足',
    '该商品暂无评论~': '该商品暂无评论~',
    '暂无相关商品': '暂无相关商品',
    '库存不足！': '库存不足！',
    '超过购买上限!': '超过购买上限!',
    '未登录，请先登录!': '未登录，请先登录!',
    '加入购物车成功': '加入购物车成功',
    '暂无商品服务~': '暂无商品服务~',
    '该商品暂无详情~': '该商品暂无详情~',
    'QQ': 'QQ',
    '新浪': '新浪',
    '微信': '微信',
    '分享到微信': '分享到微信',


    //购物车页面
    '全选': '全选',
    '商品信息': '商品信息',
    '单价': '单价',
    '小计（元）': '小计（元）',
    '操作': '操作',
    '领取优惠券': '领取优惠券',
    '满减': '满减',
    '去凑单': '去凑单',
    '再逛逛': '再逛逛',
    '移入收藏夹': '移入收藏夹',
    '删除': '删除',
    '失效商品': '失效商品',
    '失效': '失效',
    '已下架': '已下架',
    '删除选中的商品': '删除选中的商品',
    '清空失效的商品': '清空失效的商品',
    '已选择': '已选择',
    '件商品': '件商品',
    '合计': '合计',
    '促销减': '促销减',
    '去结算': '去结算',
    '确定清空失效商品？': '确定清空失效商品？',
    '取 消': '取 消',
    '确 定': '确 定',
    '确定删除选中商品？': '确定删除选中商品？',
    '剩余': "剩余",
    '件': '件',
    '确定': '确定',
    '取消': '取消',
    '确认': '确认',
    '最低限购1件!': '最低限购1件!',
    '数量超出购买范围！': '数量超出购买范围！',
    '超过购买限制!': '超过购买限制!',
    '不能输入非0数字!': '不能输入非0数字!',
    '移入收藏夹成功！': '移入收藏夹成功！',
    '删除成功！': '删除成功！',
    '清空失效商品成功！': '清空失效商品成功！',
    '结算商品不能为空！': '结算商品不能为空！',
    '使用优惠券/积分抵用': '使用优惠券/积分抵用',
    '商品清单': '商品清单',
    '商品总额(含运费)': '商品总额(含运费)',
    '共优惠': '共优惠',
    '应付金额': '应付金额',
    '送货至': '送货至',
    '选填，给卖家留言备注': '选填，给卖家留言备注',

    //我的收藏
    '我的': '我的',
    '收藏的商品': '收藏的商品',
    '收藏的店铺': '收藏的店铺',
    '热门推荐': '热门推荐',
    '这里空空如也，快去首页逛逛吧~': '这里空空如也，快去首页逛逛吧~',
    '去首页 > >': '去首页 > >',
    '已加入购物车': '已加入购物车',
    '普通关注': '普通关注',
    '特别关注': '特别关注',
    ' 设为特殊关注': ' 设为特殊关注',
    '取消特殊关注': '取消特殊关注',
    '本月上新': '本月上新',
    '热销推荐': '热销推荐',
    '取消收藏': '取消收藏',
    '管理': '管理',
    '设为特殊关注': '设为特殊关注',
    '您还没关注过店铺，快去': '您还没关注过店铺，快去',
    '逛逛吧~': '逛逛吧~',
    '您还没关注过商品，快去': '您还没关注过商品，快去',


    //首页公共头部——搜索模块
    '请输入关键词': '请输入关键词',
    '热门搜索': '热门搜索',
    '我的购物车': '我的购物车',
    '购物车中还没有商品，赶紧选购吧': '购物车中还没有商品，赶紧选购吧',

    //店铺公共头部数据——导航和店铺分类
    '综合评分': '综合评分',
    '店铺评分': '店铺评分',
    '描述相符': '描述相符',
    '服务态度': '服务态度',
    '服务承诺': '服务承诺',
    '正品保障': '正品保障',
    '发货速度': '发货速度',
    '客服电话': '客服电话',
    '店铺首页': '店铺首页',
    '搜本店': '搜本店',
    '本店全部分类': '本店全部分类',
    '所有商品': '所有商品',
    '请输入...': '请输入...',

    //店铺商品列表
    '件相关商品': '件相关商品',
    '共': '共',

    //首页装修
    '人购买': '人购买',
    '立即抢购': '立即抢购',
    '查看更多': '查看更多',
    '此处添加商品': '此处添加商品',
    '此处添加【360*560】图片': '此处添加【360*560】图片',
    '请选择商品，数量建议选择3的倍数，最少3个，最多12个': '请选择商品，数量建议选择3的倍数，最少3个，最多12个',
    '请上传500*80的图片': '请上传500*80的图片',
    '平台还未设置公告': '平台还未设置公告',
    '平台还未设置资讯': '平台还未设置资讯',
    '该场景还没有商品': '该场景还没有商品',
    '请选择供货商，数量建议选择5的倍数，最少5个，最多20个': '请选择供货商，数量建议选择5的倍数，最少5个，最多20个',

    //我的足迹
    '关注': '关注',

    //个人中心
    '会员收货地址': '会员收货地址',
    '我的财产': '我的财产',
    '余额': '余额',
    '充值': '充值',
    '领券': '领券',
    '查看': '查看',
    '售后/退货': '售后/退货',
    '进行中的订单': '进行中的订单',
    '去支付': '去支付',
    '我的关注': '我的关注',
    '商品关注': '商品关注',
    '店铺关注': '店铺关注',
    '积分': '积分',
    '这里空空如也，快去挑选合适的商品吧~': '这里空空如也，快去挑选合适的商品吧~',
    '退款金额不可为0或者负值！': '退款金额不可为0或者负值！',
    '仅支持JPG、GIF、PNG、JPEG、BMP格式;文件大小请在4.0MB之内。': '仅支持JPG、GIF、PNG、JPEG、BMP格式;文件大小请在4.0MB之内。',
    //订单列表
    '订单': '订单',
    '商品名称/订单编号': '商品名称/订单编号',
    '订单号/商品名称/店铺名称': '订单号/商品名称/店铺名称',
    '订单号/商品名称': '订单号/商品名称',
    '我的订单': '我的订单',
    '全部订单': '全部订单',
    '待支付': '待支付',
    '待付款': '待付款',
    '待发货': '待发货',
    '待收货': '待收货',
    '已完成': '已完成',
    '待评价': '待评价',
    '请输入内容': '请输入内容',
    '搜索订单': '搜索订单',
    '售后维权': '售后维权',
    '订单金额': '订单金额',
    '下单时间': '下单时间',
    '订单号': '订单号',
    '订单详情': '订单详情',
    '普通订单': '普通订单',
    '修改地址': '修改地址',
    '查看物流': '查看物流',
    '确认收货': '确认收货',
    '确认收货?': '确认收货?',
    '取消订单': '取消订单',
    '立即支付': '立即支付',
    '删除订单': '删除订单',
    '取消订单理由': '取消订单理由',
    '物流信息': '物流信息',
    '运单号': '运单号',
    '承运人': '承运人',
    '联系人': '联系人',
    '联系电话': '联系电话',
    '更换地址': '更换地址',
    '收货人': '收货人',
    '联系方式': '联系方式',
    '提交修改': '提交修改',
    '修改地址成功': '修改地址成功',
    '确认删除该订单?': '确认删除该订单?',
    '提示': '提示',
    '删除订单成功': '删除订单成功',
    '请选择取消理由': '请选择取消理由',
    '取消订单成功': '取消订单成功',
    '确认收货成功': '确认收货成功',
    '暂无订单～': '暂无订单～',
    '满优惠': '满优惠',
    '平台优惠券': '平台优惠券',
    '店铺优惠券': '店铺优惠券',
    // 售后详情页面
    '退款退货单号': '退款退货单号',
    '提交申请': '提交申请',
    '审核中': '审核中',
    '完成': '完成',
    '退款金额': '退款金额',
    '问题描述': '问题描述',
    '时间': '时间',
    '平台审核备注': '平台审核备注',
    '退款凭证': '退款凭证',
    '退款单号': '退款单号',

    // 申请售后

    '添加/编辑': '添加/编辑',
    '（同订单商品可批量申请）': '（同订单商品可批量申请）',
    '退款金额可修改，最多': '退款金额可修改，最多',
    '退款金额不可修改，最多': '退款金额不可修改，最多',
    '含运费': '含运费',
    '不含运费': '不含运费',
    '修改金额': '修改金额',
    '申请件数': '申请件数',
    '请输入申请件数': '请输入申请件数',
    '申请类型': '申请类型',
    '货物状态': '货物状态',
    '退款原因': '退款原因',
    '请选择退款原因': '请选择退款原因',
    '退货原因': '退货原因',
    '请选择退货原因': '请选择退货原因',
    '请输入退款金额': '请输入退款金额',
    '请输入问题描述(选填)': '请输入问题描述(选填)',
    '批量售后商品选择': '批量售后商品选择',
    '上传凭证': '上传凭证',
    '未收到货': '未收到货',
    '已收到货': '已收到货',
    '退款退货': '退款退货',
    '不可超出最大申请数量': '不可超出最大申请数量',
    '不可超过最大退款金额！': '不可超过最大退款金额！',
    '退款金额不可为负值！': '退款金额不可为负值！',
    '最多上传5张！': '最多上传5张！',


    // 售后列表
    '商品明细': '商品明细',
    '申请时间': '申请时间',
    '状态': '状态',
    '发货': '发货',
    '仅退款': '仅退款',
    '退货退款': '退货退款',

    // 填写物流页面
    '用户发货': '用户发货',
    '商品名称': '商品名称',
    '购买数量': '购买数量',
    '服务类型': '服务类型',
    '快递公司': '快递公司',
    '请选择快递公司': '请选择快递公司',
    '快递单号': '快递单号',
    '请输入快递单号': '请输入快递单号',
    '提交': '提交',
    '请选择物流公司!': '请选择物流公司!',
    '请输入物流单号！': '请输入物流单号！',
    '请输入正确的物流单号！': '请输入正确的物流单号！',
    '请选择物流公司': '请选择物流公司',
    '物流单号': '物流单号',
    '请输入物流单号': '请输入物流单号',

    //消息中心——左侧公共菜单
    '消息列表': '消息列表',
    '系统消息': '系统消息',
    '订单消息': '订单消息',
    '资产消息': '资产消息',
    '售后消息': '售后消息',
    '接收设置': '接收设置',


    //待评订单
    '待评订单': '待评订单',
    '发评价，得积分，积少成多换商品': '发评价，得积分，积少成多换商品',
    '评价小贴士': '评价小贴士',
    '发表评价可获得会员积分': '发表评价可获得会员积分',
    '支付金额': '支付金额',
    '评价订单': '评价订单',

    //订单详情
    '提交订单': '提交订单',
    '支付订单': '支付订单',
    '商家发货': '商家发货',
    '送货方式': '送货方式',
    '物流公司': '物流公司',
    '运单号码': '运单号码',
    '暂无物流信息': '暂无物流信息',
    '订单信息': '订单信息',
    '发票': '发票',
    '不需要发票': '不需要发票',
    '发票抬头': '发票抬头',
    '卖家': '卖家',
    '收货人信息': '收货人信息',
    '商品总额': '商品总额',
    '商品金额': '商品金额',
    '优惠': '优惠',
    '运费金额': '运费金额',
    '实际金额': '实际金额',
    '交易关闭': '交易关闭',
    '交易流水号': '交易流水号',
    //领劵中心
    '领劵中心': '领劵中心',
    '折': '折',
    '立即领取': '立即领取',
    '已领取': '已领取',
    '已抢': '已抢',
    '已抢光': '已抢光',
    '领取成功': '领取成功',
    //个人中心-我的优惠卷
    '我的优惠券': '我的优惠券',
    '未使用': '未使用',
    '已使用': '已使用',
    '已过期': '已过期',
    '使用规则': '使用规则',
    '立即使用': '立即使用',
    //个人中心——头部公共菜单
    '消息': '消息',
    '账户管理': '账户管理',
    '确定删除这些消息吗': '确定删除这些消息吗',
    //个人中心——左侧公共菜单
    '交易中心': '交易中心',
    '交易评价/晒单': '交易评价/晒单',
    '关注中心': '关注中心',
    '我的收藏': '我的收藏',
    '我的足迹': '我的足迹',
    '客户服务': '客户服务',
    '我的退款': '我的退款',
    '我的退货': '我的退货',
    '会员中心': '会员中心',
    '会员信息': '会员信息',
    '账号安全': '账号安全',
    '手机号管理': '手机号管理',
    '邮箱管理': '邮箱管理',
    '登录密码': '登录密码',
    '支付密码': '支付密码',
    '重置密码': '重置密码',
    '我的钱包': '我的钱包',
    '我的余额': '我的余额',
    '账户充值': '账户充值',
    '提现账号': '提现账号',
    '我的优惠券': '我的优惠券',


    //个人中心-我的余额
    '总金额': '总金额',
    '可用余额': '可用余额',
    '余额明细': '余额明细',
    '充值明细': '充值明细',
    '交易日期': '交易日期',
    '收入/支出': '收入/支出',
    '变动原因': '变动原因',
    '充值金额': '充值金额',
    '商品推荐': '商品推荐',

    //utils/common
    '请输入正确的手机号': '请输入正确的手机号',
    '密码最少6位哦～': '密码最少6位哦～',
    '密码最多20位哦～': '密码最多20位哦～',
    '密码不可以有中文哦～': '密码不可以有中文哦～',
    '密码中不可以有空格哦～': '密码中不可以有空格哦～',
    '请输入短信验证码': '请输入短信验证码',
    '请输入正确的短信验证码': '请输入正确的短信验证码',
    '请输入6～20位的会员名': '请输入6～20位的会员名',
    '请输入6～会员名不能全为数字': '会员名不能全为数字',
    '会员名须由中、英文、数字、"-"及"_"组成': '会员名须由中、英文、数字、"-"及"_"组成',
    '请输入正确的图形验证码': '请输入正确的图形验证码',
    '该功能在升级中～': '该功能在升级中～',
    '请输入邮箱': '请输入邮箱',
    '请输入正确的邮箱': '请输入正确的邮箱',

    // 消息设置
    '接收': '接收',
    '不接收': '不接收',

    //消息列表
    '标记已读': '标记已读',
    '确定删除这条消息吗？': '确定删除这条消息吗？',
    '查看详情 >': '查看详情 >',
    '暂时没有消息！': '暂时没有消息！',
    '请先选择要操作的消息！': '请先选择要操作的消息！',

    //品牌中心
    '品牌中心': '品牌中心',


    //充值页面
    '1.填写充值金额': '1.填写充值金额',
    '2.在线支付': '2.在线支付',
    '3.充值完成': '3.充值完成',
    '填写充值金额': '填写充值金额',
    '充值账户': '充值账户',
    '请注意：支持支付宝支付、微信支付，在线支付成功后，充值金额会在1到5分钟内到账': '请注意：支持支付宝支付、微信支付，在线支付成功后，充值金额会在1到5分钟内到账',
    '温馨提示': '温馨提示',
    '下一步': '下一步',
    '1.充值成功后，余额可能存在延迟现象，一般1到5分钟内到账，如有问题，请咨询客服；': '1.充值成功后，余额可能存在延迟现象，一般1到5分钟内到账，如有问题，请咨询客服；',
    '2.充值金额输入值必须是不小于1且不大于5000；': '2.充值金额输入值必须是不小于1且不大于5000；',
    '3.充值完成后，您可至会员中心查看充值记录。': '3.充值完成后，您可至会员中心查看充值记录。',
    '请使用手机微信扫描下方二维码进行支付': '请使用手机微信扫描下方二维码进行支付',
    '您正在充值余额，请尽快支付': '您正在充值余额，请尽快支付',
    '应付金额': '应付金额',
    '微信支付支付': '微信支付支付',
    '如二维码过期，': '如二维码过期，',
    '刷新': '刷新',
    '重新获取二维码。': '重新获取二维码。',
    '请输入充值金额': '请输入充值金额',
    '请选择支付方式': '请选择支付方式',
    "充值成功,2s后自动跳转充值列表": "充值成功,2s后自动跳转充值列表",
    '充值成功,2s后自动跳转支付页面': '充值成功,2s后自动跳转支付页面',
    '立即充值': '立即充值',
    '选择充值方式': '选择充值方式',
    '使用微信扫码支付': '使用微信扫码支付',
    '超过充值金额上限': '超过充值金额上限',

    //收货地址
    '地址管理': '地址管理',
    '默认地址': '默认地址',
    '新增地址': '新增地址',
    '全部地址': '全部地址',
    '暂无收货地址～': '暂无收货地址～',


    // 公共购物车顶部弹框组件
    '最新加入的商品': '最新加入的商品',
    '去购物车': '去购物车',

    //空页面组件
    '暂时没有数据～': '暂时没有数据～',

    //手机号管理
    '当前手机号': '当前手机号',
    '请输入新手机号': '请输入新手机号',
    '为了保障您的账号安全，变更重要信息需进行身份验证。': '为了保障您的账号安全，变更重要信息需进行身份验证。',
    '变更过程中有任何疑问请联系在线客服解决。': '变更过程中有任何疑问请联系在线客服解决。',
    '如手机号/邮箱已不再使用无法获取验证码，请联系在线客服解决。': '如手机号/邮箱已不再使用无法获取验证码，请联系在线客服解决。',

    //邮箱管理
    '请输入邮箱号': '请输入邮箱号',
    '请输入邮箱验证码': '请输入邮箱验证码',
    '请输入正确的邮箱验证码': '请输入正确的邮箱验证码',

    //设置登陆密码
    '复杂的密码可使账号更安全且建议定期更换密码。': '复杂的密码可使账号更安全且建议定期更换密码。',

    //个人中心--会员信息
    '仅支持JPG、GIF、PNG格式；文件大小请在1.0MB之内。': '仅支持JPG、GIF、PNG格式；文件大小请在1.0MB之内。',
    '会员名：': '会员名：',
    '性别：': '性别：',
    '保密': '保密',
    '男': '男',
    '女': '女',
    '昵称：': '昵称：',
    '生日：': '生日：',
    '年': '年',
    '月': '月',
    '日': '日',
    '保存': '保存',
    '真实姓名': '真实姓名',
    //收货地址
    '设置为默认': '设置为默认',
    '删除地址': '删除地址',
    '编辑地址': '编辑地址',

    //我的积分
    '日期': '日期',
    '详细说明': '详细说明',
    '可用积分': '可用积分',
    '收入': '收入',
    '支出': '支出',
    '修改密码': '修改密码',
    '请输入新密码': '请输入新密码',
    '请输入6～20位英文、数字、符号': '请输入6～20位英文、数字、符号',
    '订单状态': '订单状态',
    //确认下单页
    '积分使用数量为': '积分使用数量为',
    '返回修改购物车': '返回修改购物车',
    '的整数倍': '的整数倍',
    '确认订单': '确认订单',
    '满': '满',
    '付款': '付款',
    '支付成功': '支付成功',
    '收货信息': '收货信息',
    '新增收货地址': '新增收货地址',
    '收货地址': '收货地址',
    '新建地址': '新建地址',
    '赠品': '赠品',
    '赠完为止': '赠完为止',
    '优惠券使用': '优惠券使用',
    '暂无可用优惠券': '暂无可用优惠券',
    '不使用优惠券': '不使用优惠券',
    '重新选择': '重新选择',
    '订单备注': '订单备注',
    '给商家留言': '给商家留言',
    '商品合计': '商品合计',
    '平台优惠券': '平台优惠券',
    '积分抵扣': '积分抵扣',
    '积分抵现': '积分抵现',
    '当前积分': '当前积分',
    '我要开发票': '我要开发票',
    '实付款（含运费）': '实付款（含运费）',
    '优惠了': '优惠了',
    '去付款': '去付款',
    '发票历史信息选择': '发票历史信息选择',
    '新增发票': '新增发票',
    '暂不开发票': '暂不开发票',
    '发票内容': '发票内容',
    '商品类别': '商品类别',
    '发票内容将显示商品名称与价格信息，发票金额为实际支付金额，不含优惠等扣减金额': '发票内容将显示商品名称与价格信息，发票金额为实际支付金额，不含优惠等扣减金额',
    '发票内容将显示本单商品所属类别及价格信息，发票金额为实际支付金额，不含优惠等扣减金额。': '发票内容将显示本单商品所属类别及价格信息，发票金额为实际支付金额，不含优惠等扣减金额。',
    '个人': '个人',
    '公司': '公司',
    '发票类型': '发票类型',
    '普通发票': "普通发票",
    '增值税专用发票': '增值税专用发票',
    '单位名称': '单位名称',
    '请在此填写单位名称': '请在此填写单位名称',
    '税号': '税号',
    '请在此填写纳税人识别号': '请在此填写纳税人识别号',
    "请输入注册地址": '请输入注册地址',
    "请输入注册电话": '请输入注册电话',
    "请输入开户银行": '请输入开户银行',
    "请输入银行账户": '请输入银行账户',
    "请输入收票人姓名": "请输入收票人姓名",
    "请输入收票人电话": "请输入收票人电话",
    "请输入收票人地址": "请输入收票人地址",
    '收票邮箱': '收票邮箱',
    "设为默认发票": '设为默认发票',
    "返回": '返回',
    '移除无货商品': '移除无货商品',
    '使用积分': '使用积分',
    '店铺总优惠': '店铺总优惠',
    '使用': '使用',
    '抵扣': '抵扣',
    '暂不使用积分': '暂不使用积分',
    '订单大于等于': '订单大于等于',
    '元': '元',
    '可用': '可用',
    '积分支付不超过订单金额的': '积分支付不超过订单金额的',
    '积分等于': '积分等于',
    "请填写发票抬头": '请填写发票抬头',
    "请填写收票邮箱": '请填写收票邮箱',
    "邮箱格式不正确，请重新输入！": '邮箱格式不正确，请重新输入！',
    "请填写单位名称": '请填写单位名称',
    "请填写纳税人税号": "请填写纳税人税号",
    "请填写正确的税号": '请填写正确的税号',
    "请填写注册地址": "请填写注册地址",
    "请填写注册电话": "请填写注册电话",
    "请填写开户银行": "请填写开户银行",
    "请填写银行账户": '请填写银行账户',
    "请填写收票人姓名": "请填写收票人姓名",
    "请填写收票人电话": '请填写收票人电话',
    "请填写收票人地址": '请填写收票人地址',
    "提交订单失败，请稍后重试": '提交订单失败，请稍后重试',
    ",2s后自动跳转订单列表": ",2s后自动跳转订单列表",
    '请在此填写发票抬头': '请在此填写发票抬头',
    '请输入收票邮箱': '请输入收票邮箱',

    //404
    '亲，抱歉! 您查看的页面失联啦...': '亲，抱歉! 您查看的页面失联啦...',
    '您可以：去其他地方逛逛': '您可以：去其他地方逛逛',
    '已收藏的商品': '已收藏的商品',

    //文章页面
    '暂无文章数据': '暂无文章数据',

    //下单页面
    '预留信息': '预留信息',
    '虚拟': '虚拟',
    '请选择': '请选择',
    '发票信息': '发票信息',
    '注册地址': '注册地址',
    '注册电话': '注册电话',
    '开户银行': '开户银行',
    '银行账户': '银行账户',
    '收票人姓名': '收票人姓名',
    '收票人电话': '收票人电话',
    '收票人地址': '收票人地址',
    '请填正确填写注册电话': '请填正确填写注册电话',
    '请填正确填写收票人电话': '请填正确填写收票人电话',
    '请输入正确的': '请输入正确的',
    '请输入': '请输入',

    //订单支付
    '订单提交成功，请您尽快付款！': '订单提交成功，请您尽快付款！',
    '请您在提交订单后': '请您在提交订单后',
    '小时内': '小时内',
    '完成支付，否则订单会自动取消': '完成支付，否则订单会自动取消',
    '收起详情': '收起详情',
    '展开详情': '展开详情',
    '使用余额支付': '使用余额支付',
    '元，目前需要在线支付': '元，目前需要在线支付',
    '元）余额不足？': '元）余额不足？',
    '马上充值': '马上充值',
    '未设置支付密码，马上去设置': '未设置支付密码，马上去设置',
    '设置密码': '设置密码',
    '选择其它支付方式': '选择其它支付方式',
    '微信支付': '微信支付',
    '扫码完成支付': '扫码完成支付',
    '暂无可用的支付方式，平台正在紧急处理中～': '暂无可用的支付方式，平台正在紧急处理中～',
    '请输入支付密码': '请输入支付密码',
    '支付成功,2s后自动跳转订单列表': '支付成功,2s后自动跳转订单列表',

    //购物车页面
    '请选择要删除的商品': '请选择要删除的商品',

    //商品详情
    '定金预售': '定金预售',
    '全款预售': '全款预售',
    '距离开始剩余：': '距离开始剩余：',
    '距离结束剩余：': '距离结束剩余：',
    '天': '天',
    '预售定金': '预售定金',
    '可抵': '可抵',
    '拼团': '拼团',
    '人团': '人团',
    '团长优惠价': '团长优惠价',
    '秒杀': '秒杀',
    '取消提醒': '取消提醒',
    '提醒我': '提醒我',
    '阶梯团': '阶梯团',
    '阶梯价': '阶梯价',
    '拼团价': '拼团价',
    '预售价': '预售价',
    '秒杀价': '秒杀价',
    '原价': '原价',
    '付尾款时间': '付尾款时间',
    '发货时间': '发货时间',
    '常用地址': '常用地址',
    '其他地址': '其他地址',
    '商品已下架，欢迎挑选其他商品~': '商品已下架，欢迎挑选其他商品~',
    '扫码参加预售': '扫码参加预售',
    '扫码开团': '扫码开团',
    '原价购买': '原价购买',
    '扫码参团': '扫码参团',

    //GoodsList
    '搜索结果': '搜索结果',

    //领券中心
    '精选': '精选',

    //首页
    '我们正在努力装修中，敬请期待～': '我们正在努力装修中，敬请期待～',
    '品牌专区': '品牌专区',
    '厂商特供，为消费者甄选天下优品': '厂商特供，为消费者甄选天下优品',
    '更多品牌': '更多品牌',
    '法意澳直采红酒': '法意澳直采红酒',
    '元起': '元起',
    '德国': '德国',
    '人气推荐': '人气推荐',
    '编辑推荐': '编辑推荐',
    '热销纵邦': '热销纵邦',
    '更多推荐': '更多推荐',
    '撕口加热即食，活力早餐帕尼尼': '撕口加热即食，活力早餐帕尼尼',
    '3千年种植历史产区，甜糯板栗仁': '3千年种植历史产区，甜糯板栗仁',
    '克': '克',
    '箱包服饰': '箱包服饰',
    '箱包': '箱包',
    '女装': '女装',
    '女鞋': '女鞋',
    '男装': '男装',
    '男鞋': '男鞋',
    '运动鞋服': '运动鞋服',
    '爆款': '爆款',
    '透气蝴蝶扣运动背心': '透气蝴蝶扣运动背心',
    '新品首发': '新品首发',
    '为你寻觅世间好物': '为你寻觅世间好物',
    '隐形不掉跟，男女同款抑菌多色船袜': '隐形不掉跟，男女同款抑菌多色船袜',

    //账号安全
    '手机号码': '手机号码',
    '绑定手机': '绑定手机',
    '，若手机丢失或停用，请及时更换': '，若手机丢失或停用，请及时更换',
    '尚未绑定手机号': '尚未绑定手机号',
    '修改号码': '修改号码',
    '绑定号码': '绑定号码',
    '电子邮箱': '电子邮箱',
    '绑定邮箱': '绑定邮箱',
    '，若邮箱已停用，请及时更换': '，若邮箱已停用，请及时更换',
    '尚未绑定邮箱': '尚未绑定邮箱',
    '修改邮箱': '修改邮箱',
    '安全性高的密码可以保障您的账号安全，建议密码为6～20位,由英文、数字或符号的组合': '安全性高的密码可以保障您的账号安全，建议密码为6～20位,由英文、数字或符号的组合',
    '在使用账户余额时需输入支付密码以保证您的资金安全': '在使用账户余额时需输入支付密码以保证您的资金安全',

    //邮箱管理
    '修改电子邮箱': '修改电子邮箱',
    '绑定电子邮箱': '绑定电子邮箱',
    '当前邮箱': '当前邮箱',
    '请输入新邮箱': '请输入新邮箱',

    //登录密码
    '修改': '修改',
    '设置': '设置',
    '请先绑定手机号，再进行登陆密码操作!': '请先绑定手机号，再进行登陆密码操作!',
    '请再次输入密码': '请再次输入密码',
    '请输入原密码': '请输入原密码',
    '请再次输入新密码': '请再次输入新密码',
    '请先绑定手机号，再进行操作!': '请先绑定手机号，再进行操作!',
    '请输入旧密码': '请输入旧密码',
    '请输入一致的新密码': '请输入一致的新密码',
    '请输入一致的密码': '请输入一致的密码',

    //支付密码
    '请先绑定手机号，再进行支付密码操作!': '请先绑定手机号，再进行支付密码操作!',
    '请再次输入支付密码': '请再次输入支付密码',
    '设置支付密码': '设置支付密码',
    '请输入原支付密码': '请输入原支付密码',
    '请输入新支付密码': '请输入新支付密码',
    '请再次输入新支付密码': '请再次输入新支付密码',
    '修改支付密码': '修改支付密码',
    '请输入旧支付密码': '请输入旧支付密码',
    '请输入一致的新支付密码': '请输入一致的新支付密码',
    '请输入一致的支付密码': '请输入一致的支付密码',

    //重置密码
    '重置支付密码': '重置支付密码',
    '请先绑定手机号，再进行重置支付密码操作!': '请先绑定手机号，再进行重置支付密码操作!',

    //用户注册协议页面
    '该手机号已被绑定，请更换其他手机号': '该手机号已被绑定，请更换其他手机号',
    '继续绑定：将解除与账号': '继续绑定：将解除与账号',
    '的绑定关系': '的绑定关系',
    '更新信息：授权信息将绑定到账号': '更新信息：授权信息将绑定到账号',
    '上': '上',
    '继续绑定': '继续绑定',
    '更新信息': '更新信息',

    //用户登录页面
    '二维码已失效': '二维码已失效',
    '点击刷新': '点击刷新',
    '登录成功': '登录成功',
    '打开手机商城，扫描二维码': '打开手机商城，扫描二维码',
    '免输入': '免输入',
    '反应快': '反应快',
    '更安全': '更安全',

    //用户注册页面
    '请同意用户注册协议及隐私政策': '请同意用户注册协议及隐私政策',

    //我的余额
    '提现': '提现',
    '提现明细': '提现明细',
    '暂无余额明细~': '暂无余额明细~',
    '暂无充值明细~': '暂无充值明细~',
    '提现单号': '提现单号',
    '提现金额': '提现金额',
    '手续费': '手续费',
    '失败原因': '失败原因',
    '暂无提现明细~': '暂无提现明细~',
    '您尚未设置支付密码，请设置支付密码后再进行后续操作。': '您尚未设置支付密码，请设置支付密码后再进行后续操作。',
    '去设置': '去设置',
    '请输入平台支付密码': '请输入平台支付密码',
    '提现功能已关闭': '提现功能已关闭',
    '密码输入错误，请重新输入': '密码输入错误，请重新输入',

    //提现详情
    '提现详情': '提现详情',
    '申请单号': '申请单号',
    '支付宝账号': '支付宝账号',
    '完成时间': '完成时间',

    //申请提现
    '温馨提示：提现手续费为': '温馨提示：提现手续费为',
    '最低提现金额为': '最低提现金额为',
    '提现方式': '提现方式',
    '请输入提现金额': '请输入提现金额',
    '本次提现手续费': '本次提现手续费',
    '剩余可提现金额不足': '剩余可提现金额不足',
    '剩余可提现金额': '剩余可提现金额',
    '请输入支付宝账号': '请输入支付宝账号',
    '请输入真实姓名': '请输入真实姓名',
    '申请提现': '申请提现',
    '请输入正确的支付密码': '请输入正确的支付密码',

    //充值
    '请输入金额': '请输入金额',

    //申请退款
    '总共上传': '总共上传',
    '张图片': '张图片',
    '描述文字': '描述文字',

    //订单详情
    '开始付尾款': '开始付尾款',
    '订单取消备注': '订单取消备注',
    '取消该订单定金不予退还,确定取消?': '取消该订单定金不予退还,确定取消?',

    //订单评价
    '商品评分': '商品评分',
    '评价晒单': '评价晒单',
    '晒图': '晒图',
    '发表评价': '发表评价',
    '评价成功': '评价成功',
    '上传图片失败': '上传图片失败',

    //填写物流信息
    '退货地址': '退货地址',

    //售后详情
    '重新申请': '重新申请',

    //积分订单详情
    '积分订单详情': '积分订单详情',
    '无需物流': '无需物流',
    '快递': '快递',
    '无': '无',

    // member/pointOrder/lis
    '积分兑换订单': '积分兑换订单',
    '积分订单': '积分订单',
    '兑换时间': '兑换时间',
    '兑换单号': '兑换单号',

    //收货地址列表
    '确定删除该地址?': '确定删除该地址?',

    //我的收藏
    '取消全选': '取消全选',
    '暂无收藏商品': '暂无收藏商品',
    '确定取消收藏吗?': '确定取消收藏吗?',
    '请选择商品': '请选择商品',

    //我的足迹
    '暂无足迹': '暂无足迹',
    '确定要清空吗?': '确定要清空吗?',

    //个人中心
    '暂无商品~': '暂无商品~',
    '暂无收藏商品~': '暂无收藏商品~',
    '暂无足迹~': '暂无足迹~',

    //会员信息
    '用户头像': '用户头像',
    '上传图片成功': '上传图片成功',
    '图像应小于4MB': '图像应小于4MB',
    '请输入昵称': '请输入昵称',
    '真实姓名包含特殊字符，请重新输入': '真实姓名包含特殊字符，请重新输入',

    // member/myPoint
    '暂无积分记录~': '暂无积分记录~',

    //消息列表
    '预约提醒': '预约提醒',

    //积分确认订单
    '积分选择': '积分选择',
    '实付款': '实付款',
    '当前积分数': '当前积分数',
    '积分不足': '积分不足',
    '暂不使用': '暂不使用',
    '新增成功': '新增成功',

    //积分商品详情
    '已兑换': '已兑换',
    '立即兑换': '立即兑换',
    '热门礼品': '热门礼品',
    '人气礼品': '人气礼品',

    //积分商品列表
    '更多': '更多',

    // service/chatPage
    '平台客服': '平台客服',
    '发送链接': '发送链接',
    '已读': '已读',
    '未读': '未读',
    '常见问题': '常见问题',
    '暂未有常见问题~': '暂未有常见问题~',
    '发送': '发送',
    '在线': '在线',
    '账号已登出': '账号已登出',
    '离线': '离线',

    // service/chatStoreList
    '展开全部': '展开全部',
    '暂无足迹～': '暂无足迹～',
    '店铺名称': '店铺名称',
    '店铺星级': '店铺星级',
    '用户评价': '用户评价',
    '物流服务': '物流服务',
    '暂无店铺商品～': '暂无店铺商品～',

    // service/chatStoreList
    '搜索最近联系人': '搜索最近联系人',
    '确定关闭该对话吗？': '确定关闭该对话吗？',
    '暂无数据～': '暂无数据～',
    '图片': '图片',
    '商品': '商品',

    //店铺首页
    '店铺不存在或已关闭': '店铺不存在或已关闭',

    // store/StoreHeaderCat
    '个商品': '个商品',

    // store/StoreItem
    '人已收藏': '人已收藏',
    '店铺已售': '店铺已售',
    '本店暂无商品~': '本店暂无商品~',

    // components/AddressOperation
    '添加收货地址': '添加收货地址',
    '请输入收货人姓名': '请输入收货人姓名',
    '电话号码': '电话号码',
    '所在地区': '所在地区',
    '请选择所在地区': '请选择所在地区',
    '详细地址': '详细地址',
    '请输入详细地址': '请输入详细地址',
    '设为默认地址': '设为默认地址',
    '请输入收货人': '请输入收货人',
    '请输入2~25个字符': '请输入2~25个字符',
    '请输入正确的电话号码': '请输入正确的电话号码',
    '请输入5~40个字符': '请输入5~40个字符',

    // components/fixedTab
    '下载': '下载',
    '关注微信': '关注微信',
    '扫码关注': '扫码关注',
    '微信公众号': '微信公众号',
    '返回顶部': '返回顶部',

    // components/FooterBottom
    '版权所有': '版权所有',
    '提供技术支持': '提供技术支持',

    // components/GoodsListCate2
    '暂无分类': '暂无分类',

    // components/GoodsListCate3
    '收起': '收起',

    // components/loadingState
    '向上滑动浏览更多数据': '向上滑动浏览更多数据',
    '数据加载中...': '数据加载中...',
    '数据加载完毕~': '数据加载完毕~',

    // components/MemberLeftNav
    '我的积分': '我的积分',

    // components/SldDiy
    '此处添加【403*高度不限】图片': '此处添加【403*高度不限】图片',
    '添加标题': '添加标题',
    '添加内容': '添加内容',
    '此处添加【186*340】图片': '此处添加【186*340】图片',
    '图片标题': '图片标题',
    '图片子标题': '图片子标题',
    '此处添加【172*106】图片': '此处添加【172*106】图片',
    '此处添加【187*120】图片': '此处添加【187*120】图片',

    '全部标为已读': '全部标为已读',
    '批量标为已读': '批量标为已读',
    '无忧质保': '无忧质保',
    '七天无理由退货': '七天无理由退货',
    '15天免费换货': '15天免费换货',
    '满99包邮': '满99包邮',
};
