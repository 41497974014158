<template>
    <div class="step">
        <ul class="step1">
            <template v-for="(item1, firstIndex) in cateData.data" :key="firstIndex">
                <li v-if="firstIndex < (hideOn ? 9 : 3)" class="step1_li"
                    @mouseover="selectTab(firstIndex, item1.categoryId)">
                    <a class="step1_a" @click.stop="toGoodsList(item1)"
                        :style="(2 < firstIndex && firstIndex < cateData.data.length - 3) ? 'position:relative' : ''"
                        :class="{ active: item1.categoryId == categoryId }">
                        {{ item1.categoryName }}
                        <div class="step2"
                            :style="[firstIndex < 3 ? 'left:0;transform:none;' : '', firstIndex > cateData.data.length - 4 ? 'left:unset;right:0;transform:none;' : '']">
                            <ul class="step2_ul">
                                <li class="step2_li flex_column_center_center" v-for="(item2, index) in cateDataFirst.data"
                                    :key="index">
                                    <a class="step2_a" @click.stop="toGoodsList(item2)">
                                        {{ item2.categoryName }}
                                    </a>
                                    <a class="step3" v-for="(item3, thirdIndex) in item2.children" :key="thirdIndex"
                                        @click.stop="toGoodsList(item3)">
                                        <div :style="{ backgroundImage: 'url(' + item3.categoryImage + ')' }"></div>
                                        <span>{{ item3.categoryName }}</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </a>
                </li>
            </template>
        </ul>
    </div>
</template>
<script>
import { getCurrentInstance, reactive, onMounted, ref, watchEffect } from 'vue'
import { useRouter, useRoute } from 'vue-router'
export default {
    name: 'CategorySort',
    props: {
        hideOn: {
            type: Boolean,
            default: true
        }
    },
    setup() {
        const router = useRouter()
        const { proxy } = getCurrentInstance()
        const L = proxy.$getCurLanguage()
        const route = useRoute()
        const cateData = reactive({ data: [] })
        const cateShow = ref(false)
        const cateDataFirst = reactive({ data: [] })
        const cateCache = reactive({ data: [] })
        const categoryId = ref(route.query.categoryId);

        watchEffect(() => {
            categoryId.value = route.query.categoryId;
        })

        const getInitData = () => {
            proxy.$get('v3/goods/front/goods/category/topCategory').then(res => {
                if (res.state == 200) {
                    cateData.data = res.data.slice(0, 12)
                }
            })
        };

        const selectTab = (index, categoryId) => {
            // cateShow.value = true
            cateDataFirst.data = []
            let params = {
                categoryId1: categoryId
            }
            let index1 = cateCache.data.findIndex(item => item.categoryId == categoryId)
            if (index1 > -1) {
                cateDataFirst.data = cateCache.data[index1].list
            } else {
                getSortChild(index, params)
            }
        };

        const getSortChild = (index, params) => {
            proxy.$get('v3/goods/front/goods/category/bottomCategory', params).then(res => {
                if (res.state == 200) {
                    let index1 = cateCache.data.findIndex(item => item.categoryId == params.categoryId1)
                    if (index1 > -1) {
                        cateDataFirst.data = cateCache.data[index1].list
                    } else {
                        cateDataFirst.data = res.data
                        cateCache.data.push({ categoryId: params.categoryId1, list: res.data })
                    }
                }
            })
        };

        const toGoodsList = (item) => {
            let newWin = router.resolve({
                path: '/goods/list',
                query: {
                    categoryId: item.categoryId,
                    g: item.grade,
                    pid: item.pid
                }
            })
            window.open(newWin.href, '_blank');
        };

        onMounted(() => {
            getInitData(0, {})
        })
        return { L, cateData, cateDataFirst, selectTab, cateShow, toGoodsList, categoryId }
    }
}
</script>
<style lang="scss" scoped>
.index {
    background: #f8f8f8;
    padding-bottom: 10px;
}

ul,
ol,
li {
    list-style: none;
}

a,
a:visited,
a:link {
    text-decoration: none;
    color: #666;
}

.step {
    display: inline-block;
    height: 45px;
    line-height: 45px;
    color: #333333;
    font-size: 16px;
    box-sizing: border-box;
    padding: 0 7px;
    flex-shrink: 0;

    .step1 {
        position: relative;
        display: flex;
        white-space: nowrap;

        .step1_li {
            .step1_a {
                // position: relative;
                display: inline-block;
                width: -webkit-max-content;
                width: -moz-max-content;
                width: max-content;
                height: 44px;
                line-height: 44px;
                color: #333333;
                font-size: 14px;
                font-weight: 700;
                box-sizing: border-box;
                margin-left: 10px;
                margin-right: 10px;
                padding-left: 6px;
                padding-right: 6px;
                cursor: pointer;

                &.active,
                &:hover {
                    color: rgb(204, 151, 86);
                    border-bottom: 3px solid rgb(204, 151, 86);
                }

                &:hover {


                    .step2 {
                        display: block;
                    }
                }

                .step2 {
                    display: none;
                    position: absolute;
                    top: 45px;
                    left: 50%;
                    transform: translateX(-50%);
                    z-index: 999;
                    max-height: 430px;
                    overflow-y: auto;
                    padding: 14px 20px;
                    background: #ffffff;

                    &:hover {
                        display: block;
                    }

                    .step2_ul {
                        display: flex;
                        align-items: flex-start;
                        white-space: nowrap;

                        a {
                            flex-shrink: 0;
                            position: relative;
                            height: 44px;
                            display: inline-block;
                            box-sizing: border-box;
                            color: #333333;
                            margin-left: 10px;
                            margin-right: 10px;
                            line-height: 44px;
                            padding: 0 7px;
                            width: -webkit-max-content;
                            width: -moz-max-content;
                            width: max-content;
                        }

                        .step2_a {
                            min-width: 100px;
                            margin: 0;
                            font-size: 16px;
                            font-weight: 700;
                            border-bottom: 1px solid rgb(221, 221, 221);
                        }

                        .step3 {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            min-width: 70px;
                            margin: 15px 5px;
                            max-height: 48px;
                            font-size: 14px;
                            overflow-wrap: break-word;
                            overflow: hidden;

                            div {
                                width: 50px;
                                height: 50px;
                                background-repeat: no-repeat;
                                background-position: center;
                                background-size: contain;
                            }

                            span {
                                display: inline-block;
                                min-width: 60px;
                                padding-left: 6px;
                            }

                            &:hover {
                                color: var(--color_main);
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>