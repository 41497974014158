<template>
  <div class="index">
    <!--<template v-if="firstLoading">-->
      <!--<div class="skeleton_banner"></div>-->
      <!--<div class="w_sld_react_1210 adv_04_wrap skeleton">-->
        <!--<div class="floor_title">-->
          <!--<h2>-->
            <!--<font>&nbsp;</font>-->
            <!--<span></span>-->
            <!--<font>&nbsp;</font>-->
          <!--</h2>-->
        <!--</div>-->
        <!--<div class="floor_goods">-->
          <!--<div class="item" v-for="(item_main, index_main) in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]" v-bind:key="index_main">-->
            <!--<div class="wrap">-->
              <!--<a href="javascript:void(0)" class="example_text"></a>-->
              <!--<p class="title">-->
                <!--<a href="javascript:void(0)">{{ item_main }}</a>-->
              <!--</p>-->
              <!--<p class="price">-->
                <!--<span></span>-->
              <!--</p>-->
            <!--</div>-->
          <!--</div>-->
        <!--</div>-->
      <!--</div>-->
    <!--</template>-->


  <!--<div class="header">-->
    <!--<div class="left">-->
      <!--<div class="title">分类</div>-->
      <!--<div class="cell" v-for="item in categoryList.data">-->
        <!--<router-link class="item" :to="`/goods/list?categoryId=${item[0].id}&g=1&pid=${0}`">-->
          <!--{{ item[0].name }}-->
        <!--</router-link>-->
<!--&lt;!&ndash;        <div  @click="handleCategortClick(item,0)">{{item[0].name}}</div>&ndash;&gt;-->
        <!--<div class="sp">/</div>-->
        <!--<router-link class="item" :to="`/goods/list?categoryId=${item[1].id}&g=2&pid=${item[0].id}`">-->
          <!--{{ item[1].name }}-->
        <!--</router-link>-->
<!--&lt;!&ndash;        <div class="item" @click="handleCategortClick(item,1)">{{item[1].name}}</div>&ndash;&gt;-->
        <!--<div  class="sp">/</div>-->
        <!--<router-link class="item" :to="`/goods/list?categoryId=${item[2].id}&g=3&pid=${item[1].id}`">-->
          <!--{{ item[2].name }}-->
        <!--</router-link>-->
      <!--</div>-->




    <!--</div>-->
    <!--<div style="width:10px"></div>-->
    <!--<div class="swiper">-->
      <!--<el-carousel   :interval="5000" arrow="always" ref="carousel" v-if="BannerList.data && BannerList.data.length>0">-->
        <!--<el-carousel-item v-for="(item,index) in BannerList.data" :key="item">-->

          <!--&lt;!&ndash;<div>{{index}}</div>&ndash;&gt;-->
          <!--<div @click="goGoodsDetail(item.info.defaultProductId)" style="position: relative;display: flex;justify-content: center;align-items: center;background-color: #fff">-->
            <!--<div class="banner-title" style="">思途普洱茶专栏/{{item.link_value}}</div>-->
            <!--<img style="height: 300px" :src="item.imgUrl">-->

          <!--</div>-->

        <!--</el-carousel-item>-->
      <!--</el-carousel>-->
    <!--</div>-->
    <!--&lt;!&ndash;<div  class="login">&ndash;&gt;-->

    <!--&lt;!&ndash;</div>&ndash;&gt;-->
  <!--</div>-->


    <div style="background-color: #fff;width: 1200px;margin: 0 auto;">
      <SldDiy v-if="decorateData.data.length" :decorateData="decorateData" @adv19="handleAdv19" />
      <!--<index-list  v-if="goodsList.data.length" :decorateData="goodsList"></index-list>-->


    </div>

  </div>
</template>
<script>
import { reactive, getCurrentInstance, ref, onMounted } from "vue";
import { useRouter, useRoute } from 'vue-router';
import SldDiy from "../../components/SldDiy";
import OpenScreen from "../../components/OpenScreen";
import { useStore } from "vuex";
import SldCommonEmpty from "../../components/SldCommonEmpty";
import { getQueryVariable } from '@/utils/common.js'
import IndexList from "./IndexList";

export default {
  name: "index",
  components: {
    IndexList,
    SldDiy,
    OpenScreen,
    SldCommonEmpty
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const adv_21_right_icon = require("../../assets/decorate/right_more_icon.png");
    const adv_24_let_default_pic = require("../../assets/decorate/adv_24_let_default_pic.png");
    const decorateData = reactive({ data: [] }); //装修数据
    const openScreenData = reactive({ data: {} }); //开屏数据
    const goodsList = reactive({ data: [] }); //装修数据
    const categoryList = reactive({ data: [] }); //装修数据
    const BannerList = reactive({ data: [] }); //装修数据
    const firstLoading = ref(true); //是否第一次加载
    const { proxy } = getCurrentInstance();
    const L = proxy.$getCurLanguage();
    const show_open_screen = ref(false);
    const store = useStore();

    const goGoodsDetail = productId => {
      let newWin = router.resolve({
        path: "/goods/detail",
        query: {
          productId,
        }
      });
      window.open(newWin.href, "_blank")
    };
    const handleCategortClick=(data,i)=>{
     let id= data[i].id;
   //     console.log(data)
    }
    const getInitData = () => {
      let param = {}
      if (route.query.type == 'view' && route.query.id) param.decoId = route.query.id
      proxy.$get("v3/system/front/pcDeco/index", param).then(res => {
        firstLoading.value = false;
        if (res.state == 200) {
          if (res.data.data) {
            decorateData.data = JSON.parse(res.data.data.replace(/&quot;/g, "\""));
          }
          console.log("------------")
          console.log( decorateData.data)
          decorateData.data.map(item => {
            item.json_data = item.json
            if (item.json_data && item.json_data.type == 'adv_19') {
              item.json_data.data.map(child => {
                child.cur_tab = 0;
              })
            }



            if (item.json_data && item.json_data.type == 'main_banner') {
              item.json_data.data = item.json_data.data.filter(i => i.imgUrl)


            }
            // BannerList.data=[{},{}]
            if (item.json && item.json.type == 'adv_07') {

              BannerList.data=item.json_data.data.map((item2)=>{
                return {...item2}
              });

              console.log( BannerList.data)
              console.log( JSON.stringify(BannerList.data))
              // debugger
              //
              // BannerList.data=[{}]
            }
          });
        //  console.log(JSON.stringify(decorateData))


        }

      });


      proxy.$get("v3/goods/front/goods/goodsList", {}).then((res) => {
        if (res.state == 200) {
          let result = res.data;
          goodsList.data = result.list;
          goodsList.data.map(item => item.goodsPrice = new Number(item.goodsPrice).toFixed(2))
        } else {

        }
      });
    };
    const getOpenScreen = () => {
      proxy.$get("v3/system/front/pcDeco/firstAdv").then(res => {
        if (res.state == 200) {
          openScreenData.data = JSON.parse(res.data.data.replace(/&quot;/g, "\""));
          if (openScreenData.data.imgUrl && openScreenData.data.show_switch && !route.query.type) {
            if (JSON.stringify(store.state.openScreenInfo) == "{}") {
              openScreenData.data.time = new Date().getTime()
              store.commit("updateOpenScreenInfo", openScreenData.data);
              show_open_screen.value = true;
              document.body.style.overflow = "hidden";
            } else if (
              store.state.openScreenInfo.show_radio_sele == openScreenData.data.show_radio_sele
            ) {
              if (store.state.openScreenInfo.show_radio_sele == "one") {
                if (openScreenData.data.time - new Date().getTime() > 86400) {
                  openScreenData.data.time = new Date().getTime()
                  store.commit("updateOpenScreenInfo", openScreenData.data);
                  show_open_screen.value = true;
                  document.body.style.overflow = "hidden";
                }
              } else if (store.state.openScreenInfo.show_radio_sele == "more") {
                store.commit("updateOpenScreenInfo", openScreenData.data);
                show_open_screen.value = true;
                document.body.style.overflow = "hidden";
              }
            } else {
              openScreenData.data.time = new Date().getTime()
              store.commit("updateOpenScreenInfo", openScreenData.data);
              show_open_screen.value = true;
              document.body.style.overflow = "hidden";
            }
          }
        }
      });
    };

    const loadC=()=>{
      let list=[];
      proxy.$get('v3/goods/front/goods/category/bottomCategory', { categoryId1:1 }).then(res => {

        let item=[{name:"思途",id:"1"}]
        for (let i = 0; i < res.data.length ; i++) {
          let d1=res.data[i];
          for (let j = 0; j < d1.children.length; j++) {
            let d2=d1.children[j];
            let item2=[...item];
            item2.push({name:d1.categoryName,id:d1.categoryId});
            item2.push({name:d2.categoryName,id:d2.categoryId})
            list.push(item2);
          }
        }
        categoryList.data=list;
      })
    };
    const closeScreen = () => {
      show_open_screen.value = false;
      document.body.style.overflow = "";
    };
    router.beforeEach((to, from, next) => {
      document.body.style.overflow = "";
      next()
    })
    getOpenScreen();
    getInitData();

    const handleAdv19 = (data) => {
      let tmp_data = decorateData.data.filter(item => item.dataId == data.dataId)[0];
      tmp_data.json_data.data[data.left_or_right_index]['cur_tab'] = data.tab_index;
    }

    onMounted(() => {
      loadC();
    })

    return {
      goodsList,
      adv_21_right_icon,
      adv_24_let_default_pic,
      decorateData,
      firstLoading,
      show_open_screen,
      openScreenData,
      handleAdv19,
      closeScreen,
      categoryList,
      BannerList,
      goGoodsDetail,
      handleCategortClick,
      L
    };
  }
};
</script>
<style lang="scss">
.diy_block {
  .swiper {
    .el-carousel__container {
      height: 200px;
      display: flex;
      justify-content: center;
    }
  }
}
</style>
<style lang="scss" scoped>
@import "../../style/decorate.scss";
.banner-title{
  height: 30px;
  background-color: rgba(12, 12, 12, 0.03);
  width: 100%;
  top: 0px;
  line-height: 30px;
  padding-left: 10px;
  position: absolute;
  font-size: 16px
}
.header{
  display: flex;
  width: 1200px;
  margin: 0 auto;
  .left{
    border-radius: 5%;
    width: 250px;
    background-color: #F7F9FA;
    height: 300px;
    padding: 20px;
    line-height: 30px;
    font-size: 14px;
    padding-top: 10px;
    .title{
      font-weight: bold;
      font-size: 16px;
      margin-left: 5px;

    }
    .cell:hover{
      cursor: pointer;
     // font-weight: bold;
    }
    .cell{
      width: 100%;
      display: flex;
      .item{
        width: 50px;
        text-align: center;
      }
      .item:hover{
        text-decoration: underline;
      }
      .sp{
        text-align: center;
        //margin-left: 20px;
        width: 20px;
      }
    }
  }
  .swiper{
    flex: 1;
  }
  .login{
    width: 200px;
  }

}
.index {
  min-height: 456px;
  padding-bottom: 10px;
  background-color: #E4E5E5;
}
.el-carousel__item h3 {
  color: #475669;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
  text-align: center;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.skeleton_banner {
  width: 100%;
  height: 470px;
  background: $colorSkeleton;
  margin-bottom: 10px;
}

.skeleton {
  &.adv_04_wrap .floor_title h2 span {
    background: $colorSkeleton;
    display: inline-block;
    width: 200px;
  }

  &.adv_04_wrap .floor_goods .item .wrap .title {
    background: $colorSkeleton;
    width: 100%;

    a {
      color: transparent;
    }
  }

  &.adv_04_wrap .floor_goods .item .wrap .price {
    width: 100%;
    height: 25px;
  }

  &.adv_04_wrap .floor_goods .item .wrap .price span {
    display: inline-block;
    width: 70px;
    height: 100%;
    background: $colorSkeleton;
  }
}

.diy_block {
  width: 1210px;
  background-color: #fff;
  margin: 0 auto;
  overflow: hidden;

  .header {
    height: 56px;
    line-height: 56px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 14px;
    padding-right: 14px;

    .left {
      h3 {
        font-size: 28px;
        font-weight: 400;
        margin-right: 20px;
        cursor: pointer;

        &:hover {
          color: var(--color_main);
        }
      }

      .nav_list {
        .nav_item {
          position: relative;
          width: 70px;
          height: 28px;
          line-height: 28px;
          font-size: 14px;
          text-align: center;
          margin-left: 50px;
          margin-right: 10px;
          cursor: pointer;

          &.active,
          &:hover {
            color: var(--color_main);
          }

          &.active {
            &:after {
              content: '';
              position: absolute;
              left: 50%;
              bottom: 0;
              margin-left: -33px;
              z-index: 9;
              width: 66px;
              height: 2px;
              background: var(--color_main);
            }
          }
        }
      }

      .sub_list {
        font-size: 13px;

        b {
          margin-left: 10px;
          margin-right: 10px;
        }
      }
    }

    .right {}

    a {
      color: #333;

      &:hover {
        color: var(--color_main);
      }
    }
  }

  .banner {
    margin-bottom: 10px;

    img {
      width: 100%;
      cursor: pointer;
    }
  }

  .swiper {
    .swiper_list {
      padding: 10px 20px;

      .swiper_item {
        width: 25%;
        text-align: center;
        cursor: default;
        flex-shrink: 0;

        .swiper_imgs {
          width: 265px;
          height: 265px;
          cursor: pointer;

          .swiper_img_auto,
          .swiper_img_hover {
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-size: contain;
          }

          .swiper_img_hover {
            display: none;
          }
        }

        .swiper_info {
          width: 265px;
          height: 91px;

          .swiper_tag {
            display: inline-block;
            height: 20px;
            line-height: 20px;
            font-size: 12px;
            color: #fff;
            margin-top: 7px;
            margin-bottom: 2px;
            padding-left: 5px;
            padding-right: 5px;

            &.hot {
              background-color: #E36844;
            }

            &.virtual {
              background-color: #B0CA85;
            }
          }

          .swiper_title {
            width: 150px;
            line-height: 18px;
            margin: 2px auto 4px;
            overflow: hidden;
          }

          .swiper_price {
            margin-bottom: 5px;
            color: $colorMain;

            span {
              color: #999;
              font-size: 12px;
              text-decoration: line-through;
              margin-left: 6px;
            }
          }
        }

        &:hover {
          .swiper_imgs {
            .swiper_img_auto {
              display: none;
            }

            .swiper_img_hover {
              display: block;
            }
          }

          .swiper_info {
            box-shadow: 1px 1px 8px rgb(0 0 0 / 20%);
            background-color: #f4f0ea;
          }
        }
      }
    }
  }

  .product_two_list {
    width: 1210px;
    height: 570px;
    overflow: hidden;

    .product_item {
      float: left;
      width: 240px;
      height: 280px;
      background-color: #fff;
      overflow: hidden;
      margin-left: 20px;
      text-align: center;

      &.first {
        width: 390px;
        height: 570px;

        .product_img {
          width: 390px;
          height: 390px;

          img {
            max-width: 390px;
            max-height: 390px;
          }
        }

        .product_info {
          width: 390px;
          height: 165px;
          line-height: 26px;
          font-size: 18px;
          margin-bottom: 13px;

          .product_title {
            width: 250px;
            line-height: 24px;
            margin-top: 40px;
            margin-bottom: 10px;
            padding: 0;
          }
        }
      }

      .product_img {
        width: 200px;
        height: 200px;
        overflow: hidden;
        cursor: pointer;

        img {
          max-width: 200px;
          max-height: 200px;
          transition: all 1s ease;
        }
      }

      .product_info {
        width: 200px;
        height: 65px;
        border: 1px solid #F4F0EA;

        .product_title {
          width: 150px;
          height: 46px;
          line-height: 18px;
          margin: 0 auto;
          padding-top: 6px;
          padding-bottom: 4px;
          overflow: hidden;
          cursor: pointer;
        }

        .price {
          margin-bottom: 5px;
          color: $colorMain;

          span {
            color: #999;
            font-size: 12px;
            text-decoration: line-through;
            margin-left: 6px;
          }
        }
      }

      &:hover {
        .product_img {
          img {
            transform: scale(1.05);
          }
        }

        .product_info {
          .product_title {
            color: var(--color_main);
          }
        }
      }
    }
  }

  .product_three_list {
    width: 100%;
    height: 320px;

    .product_item {
      position: relative;
      width: 397px;
      height: 320px;

      &.row,
      .list {
        overflow: hidden;
        cursor: pointer;
        transition: all .3s ease;

        &:hover {
          color: #D65A17;
        }
      }

      &.row {
        text-align: center;
        margin-right: 10px;
      }

      .list {
        position: relative;
        width: 397px;
        height: 155px;

        .title {
          width: unset;
          top: 30px;
          left: 20px;
          padding-bottom: 12px;

          &:after {
            left: 0;
            margin-left: 0;
          }
        }

        .desc {
          width: auto;
          top: 86px;
          left: 20px;
        }
      }

      .title,
      .desc {
        width: 397px;
        position: absolute;
        z-index: 9;
      }

      .title {
        top: 48px;
        font-size: 24px;
        padding-bottom: 18px;

        &:after {
          content: '';
          position: absolute;
          left: 50%;
          bottom: 0;
          margin-left: -22px;
          width: 44px;
          height: 1px;
          background: #666;
        }
      }

      .desc {
        top: 114px;
        color: #4B5459;
        font-size: 14px;
      }

      .product_item_bg {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        transition: all 1s ease;

        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }

  .product_four_list {
    .product_item {
      text-align: center;
      width: 303px;
      padding-bottom: 10px;

      .product_img {
        width: 303px;
        height: 303px;
        border: 1px solid rgb(244, 244, 244);
        overflow: hidden;
        margin: 0 auto;

        img {
          width: 100%;
          height: 100%;
          transition: all 1s ease;
          cursor: pointer;

          &:hover {
            transform: scale(1.05);
          }
        }
      }

      .product_info {
        width: 303px;
        height: 84px;
        font-size: 13px;

        .product_tag {
          height: 20px;
          line-height: 20px;
          font-size: 12px;
          color: #fff;
          margin-bottom: 2px;
          padding-left: 5px;
          padding-right: 5px;

          &.hot {
            background-color: #E36844;
          }

          &.virtual {
            background-color: #B0CA85;
          }
        }

        .product_title {
          height: 20px;
          line-height: 20px;
          color: #333;
          font-weight: 700;
          margin-bottom: 3px;
          padding-left: 10px;
          padding-right: 10px;
          cursor: pointer;
        }

        .product_price {
          line-height: 15px;
          color: #d4282d;
          margin-bottom: 15px;
        }
      }
    }
  }
}</style>