/*
 * @Author: your name
 * @Date: 2021-01-08 21:09:07
 * @LastEditTime: 2021-01-15 11:36:36
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /java-pc/src/store/index.js
 */
import Vuex from 'vuex';
import { getLocalStorageObject, getLocalStorageStr, set16ToRgb } from '../utils/common';
import { updateStatCommonProperty } from '../utils/stat'
import { get } from '@/utils/request'

export default Vuex.createStore({
	state: {
		loginFlag: getLocalStorageStr('loginFlag') ? true : false,
		memberInfo: getLocalStorageObject('memberInfo'),//用户信息
		cartListData: getLocalStorageObject('cartListData'), //购物车信息
		openScreenInfo: getLocalStorageObject('openScreenInfo'), //购物车信息
		configInfo: getLocalStorageObject('configInfo'), //系统配置信息
		chatBaseInfo: getLocalStorageObject('chatBaseInfo') ? getLocalStorageObject('chatBaseInfo') : {},//聊天的基本信息，包含会员id、头像、店铺id、头像
		x_diyStyle: getLocalStorageObject('x_diyStyle')
	},
	mutations: {
		//更新用户信息
		updateMemberInfo(state, payload) {
			state.memberInfo = payload;
			if (payload.memberId != undefined && payload.memberId) {
				state.loginFlag = true;
				updateStatCommonProperty({ memberId: payload.memberId });//登录成功需要更新统计里面的会员id
			} else {
				state.loginFlag = false;
			}
			localStorage.setItem('memberInfo', JSON.stringify(state.memberInfo));
			localStorage.setItem('loginFlag', state.loginFlag)
		},
		//更新购物车信息
		updateCartListData(state, payload) {
			if (payload != undefined) {
				state.cartListData = payload;
			} else {
				state.cartListData = {};
			}
			localStorage.setItem('cartListData', JSON.stringify(state.cartListData));
		},
		//更新开屏图信息
		updateOpenScreenInfo(state, payload) {
			state.openScreenInfo = payload;
			localStorage.setItem('openScreenInfo', JSON.stringify(state.openScreenInfo));
		},
		//更新系统配置信息
		updateConfigInfo(state, payload) {
			state.configInfo = payload;
			localStorage.setItem('configInfo', JSON.stringify(state.configInfo));
		},
		//清空vuex里所有数据
		clearAllData(state) {
			state.memberInfo = {};
			state.loginFlag = false;
			state.cartListData = {};
			state.chatBaseInfo = {}
			localStorage.removeItem('memberInfo')
			localStorage.removeItem('cartListData')
			localStorage.removeItem('refresh_token')
			localStorage.removeItem('access_token')
			localStorage.removeItem('loginFlag')
			localStorage.removeItem('chatBaseInfo')
			localStorage.removeItem('addressId')
			updateStatCommonProperty({ memberId: 0 });//退出登录需要将会员id置为0
		},
		//保存聊天的会员id、会员头像，店铺id、店铺头像
		saveChatBaseInfo(state, payload) {
			state.chatBaseInfo = payload
			//缓存聊天的基本信息
			localStorage.setItem('chatBaseInfo', JSON.stringify(state.chatBaseInfo))
		},

		saveDiyStyle(state, payload) {
			state.x_diyStyle = payload
			localStorage.setItem('x_diyStyle', JSON.stringify(state.x_diyStyle))
		}
	},

	actions: {
		//获取接口传来的颜色变量
		getDiyStyle(store_context) {
			// 主商城颜色
			const map_text = new Map([
				['--color_main', 'mainColor'],
				['--color_main_bg', 'mainLinearColor'],
				['--color_vice', 'subColor'],
				['--color_vice_bg', 'subLinearColor'],
				['--color_price', 'priceColor']
			])
			// 积分商城颜色
			const map_point_text = new Map([
				['--color_integral_main', 'mainColor'],
			])
			// 拼团颜色
			const map_pindo_text = new Map([
				['--color_spell_main', 'mainColor'],
				['--color_spell_vice', 'subColor'],
			])
			// 预售颜色
			const map_presel_text = new Map([
				['--color_presell_main', 'mainColor'],
			])
			// 阶梯团颜色
			const pc_ladder_mall_style = new Map([
				['--color_ladder_main', 'mainColor'],
				['--color_ladder_vice', 'subColor'],
			])
			// 秒杀颜色
			const pc_seckill_style = new Map([
				['--color_seckill_main', 'mainColor'],
				['--color_seckill_vice', 'subColor'],
			])
			// 优惠券颜色
			const pc_coupon_style = new Map([
				['--color_coupon_main', 'mainColor'],
				['--color_coupon_main_bg', 'mainLinearColor'],
			])
			/*
			pc_mall_style 主商城颜色
			 pc_integral_mall_style积分  
			pc_spell_mall_style拼团  
			pc_presell_mall_style预售  
			pc_ladder_mall_style阶梯团  
			pc_seckill_mall_style秒杀 
			pc_coupon_mall_style 优惠券 */
			return new Promise((resolve, reject) => {
				get('v3/system/front/setting/getSettings', {
					names: 'pc_mall_style,pc_integral_mall_style,pc_spell_mall_style,pc_presell_mall_style,pc_ladder_mall_style,pc_seckill_mall_style,pc_coupon_mall_style'
				}).then(res => {
					if (res.state == 200) {
						if (res.data && res.data[0]) {
							let result = JSON.parse(res.data[0])
							let color_set = {}
							for (let i of map_text) {
								color_set[i[0]] = result[i[1]]
								if (i[1] == 'mainColor') {
									if (!/rgb.*?/.test(result[i[1]])) {
										color_set['--color_halo'] = set16ToRgb(result[i[1]], 0.1)
									} else {
										let rgbArr = result[i[1]].match(/((\d{1,3}))/g)
										rgbArr[3] = '0.1'
										color_set['--color_halo'] = `rgba(${rgbArr.join(',')})`
									}
								}
							}
							if (res.data && res.data[1]) {
								let results = JSON.parse(res.data[1])
								for (let i of map_point_text) {
									color_set[i[0]] = results[i[1]]
									if (i[1] == 'mainColor') {
										if (!/rgb.*?/.test(results[i[1]])) {
											color_set['--color_integral_halo'] = set16ToRgb(results[i[1]], 0.1)
										} else {
											let rgbArr = results[i[1]].match(/((\d{1,3}))/g)
											rgbArr[3] = '0.1'
											color_set['--color_integral_halo'] = `rgba(${rgbArr.join(',')})`
										}
									}
								}
							}
							if (res.data && res.data[2]) {
								let results = JSON.parse(res.data[2])
								for (let i of map_pindo_text) {
									color_set[i[0]] = results[i[1]]
									if (i[1] == 'subColor') {
										if (!/rgb.*?/.test(results[i[1]])) {
											color_set['--color_spell_halo'] = set16ToRgb(results[i[1]], 0.1)
										} else {
											let rgbArr = results[i[1]].match(/((\d{1,3}))/g)
											rgbArr[3] = '0.1'
											color_set['--color_spell_halo'] = `rgba(${rgbArr.join(',')})`
										}
									}
								}
							}
							if (res.data && res.data[3]) {
								let results = JSON.parse(res.data[3])
								for (let i of map_presel_text) {
									color_set[i[0]] = results[i[1]]
								}
							}
							if (res.data && res.data[4]) {
								let results = JSON.parse(res.data[4])
								for (let i of pc_ladder_mall_style) {
									color_set[i[0]] = results[i[1]]
									if (i[1] == 'subColor') {
										if (!/rgb.*?/.test(results[i[1]])) {
											color_set['--color_ladder_halo'] = set16ToRgb(results[i[1]], 0.1)
										} else {
											let rgbArr = results[i[1]].match(/((\d{1,3}))/g)
											rgbArr[3] = '0.1'
											color_set['--color_ladder_halo'] = `rgba(${rgbArr.join(',')})`
										}
									}
								}
							}
							if (res.data && res.data[5]) {
								let results = JSON.parse(res.data[5])
								for (let i of pc_seckill_style) {
									color_set[i[0]] = results[i[1]]
									if (i[1] == 'subColor') {
										if (!/rgb.*?/.test(results[i[1]])) {
											color_set['--color_seckill_halo'] = set16ToRgb(results[i[1]], 0.1)
										} else {
											let rgbArr = results[i[1]].match(/((\d{1,3}))/g)
											rgbArr[3] = '0.1'
											color_set['--color_seckill_halo'] = `rgba(${rgbArr.join(',')})`
										}
									}
								}
							}
							if (res.data && res.data[6]) {
								let results = JSON.parse(res.data[6])
								for (let i of pc_coupon_style) {
									color_set[i[0]] = results[i[1]]
								}
							}
							// const root = document.querySelector(':root');
							// for (let i in color_set) {
							// 	root.style.setProperty(i, color_set[i]);
							// }
							store_context.commit('saveDiyStyle', color_set)
						}
					}
					resolve()
				}).catch(err => {
					console.log(err, 'ssssss')
					reject()
				})
			})

		}
	}
})
