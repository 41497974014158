
import store from '../store'
import dynaIcon from './dynaIcon'
const diyStyle_init = {
     '--color_main': '#CD9757',
     '--color_main_bg': 'linear-gradient(90deg, #CA9352 0%, #C8893E 100%)',
     '--color_price': '#DB1107',
     '--color_vice': '#DB1107',
     '--color_vice_bg': 'linear-gradient(270deg, #DB1107 0%, #EB3E36 100%)',
     '--color_halo': '#FBF7F2',
     // 积分
     '--color_integral_main': '#9344FF',
     '--color_integral_halo': '#b54cfb17',
     // 拼团
     '--color_spell_main': '#fb2d2d',
     '--color_spell_halo': 'rgba(251,45,45,0.1)',
     '--color_spell_vice': '#ff7918',
     // 预售
     '--color_presell_main': '#fe006d',
     // 阶梯团
     '--color_ladder_main': '#FE9A22',
     '--color_ladder_halo': 'rgba(254,154,34,0.1)',
     '--color_ladder_vice': '#fc1c1c',
     //秒杀
     '--color_seckill_main': '#FE9A22',
     '--color_seckill_halo': 'rgba(254,154,34,0.1)',
     '--color_seckill_vice': '#fc1c1c',
     // 优惠券
     '--color_coupon_main': '#FE9A22',
     '--color_coupon_main_bg': 'linear-gradient(45deg, #ff7a18 0%, #fea10e 100%)',
}
export default {
     install(app) {
          app.component('dynaIcon', dynaIcon)
          const { x_diyStyle } = store.state
          const diyStyle_obj = Object.assign(diyStyle_init, x_diyStyle)
          const root = document.querySelector(':root');
          for (let i in diyStyle_obj) {
               root.style.setProperty(i, diyStyle_obj[i]);
          }
          store.commit('saveDiyStyle', diyStyle_obj)
          app.config.globalProperties.$diyStyle_var = diyStyle_obj
     }
}